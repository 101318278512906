import $ from 'mdui.jq/es/$';
import extend from 'mdui.jq/es/functions/extend';
import { JQ } from 'mdui.jq/es/JQ';
import 'mdui.jq/es/methods/addClass';
import 'mdui.jq/es/methods/appendTo';
import 'mdui.jq/es/methods/find';
import 'mdui.jq/es/methods/hasClass';
import 'mdui.jq/es/methods/off';
import 'mdui.jq/es/methods/on';
import 'mdui.jq/es/methods/parents';
import 'mdui.jq/es/methods/remove';
import { isString } from 'mdui.jq/es/utils';
import mdui from '../../mdui';
import '../../jq_extends/methods/reflow';
import '../../jq_extends/methods/transform';
import '../../jq_extends/methods/transitionEnd';
import { $document } from '../../utils/dom';
import { dequeue, queue } from '../../utils/queue';
import { startEvent } from '../../utils/touchHandler';

declare module '../../interfaces/MduiStatic' {
  interface MduiStatic {
    /**
     * ��һ�� Snackbar
     * @param message Snackbar ���ı�
     * @param options ���ò���
     */
    snackbar(message: string, options?: OPTIONS): Snackbar;

    /**
     * ��һ�� Snackbar
     * @param options ���ò���
     */
    snackbar(options: OPTIONS): Snackbar;
  }
}

type OPTIONS = {
  /**
   * Snackbar ���ı���ͨ�� `mdui.snackbar(options)` ����ʱ���ò�������Ϊ��
   */
  message?: string;

  /**
   * ���û�û�в���ʱ�೤ʱ���Զ����أ���λ�����룩��Ϊ `0` ʱ��ʾ���Զ��رգ�Ĭ��Ϊ `4000`
   */
  timeout?: number;

  /**
   * Snackbar ��λ�ã�Ĭ��Ϊ `bottom`��
   * ȡֵ��Χ������
   *   `bottom`: �·�
   *   `top`: �Ϸ�
   *   `left-top`: ���Ͻ�
   *   `left-bottom`: ���½�
   *   `right-top`: ���Ͻ�
   *   `right-bottom`: ���½�
   */
  position?:
    | 'bottom'
    | 'top'
    | 'left-top'
    | 'left-bottom'
    | 'right-top'
    | 'right-bottom';

  /**
   * ��ť���ı�
   */
  buttonText?: string;

  /**
   * ��ť���ı���ɫ����������ɫ������ɫֵ���� `red`��`#ffffff`��`rgba(255, 255, 255, 0.3)` �ȡ�Ĭ��Ϊ `#90CAF9`
   */
  buttonColor?: string;

  /**
   * �����ťʱ�Ƿ�ر� Snackbar��Ĭ��Ϊ `true`
   */
  closeOnButtonClick?: boolean;

  /**
   * ������� Snackbar ���������ʱ�Ƿ�ر� Snackbar��Ĭ��Ϊ `true`
   */
  closeOnOutsideClick?: boolean;

  /**
   * �� Snackbar �ϵ���Ļص�����������Ϊ Snackbar ��ʵ��
   */
  onClick?: (snackbar: Snackbar) => void;

  /**
   * ��� Snackbar �ϵİ�ťʱ�Ļص�����������Ϊ Snackbar ��ʵ��
   */
  onButtonClick?: (snackbar: Snackbar) => void;

  /**
   * Snackbar ��ʼ��ʱ�Ļص�����������Ϊ Snackbar ��ʵ��
   */
  onOpen?: (snackbar: Snackbar) => void;

  /**
   * Snackbar �򿪺�Ļص�����������Ϊ Snackbar ��ʵ��
   */
  onOpened?: (snackbar: Snackbar) => void;

  /**
   * Snackbar ��ʼ�ر�ʱ�Ļص�����������Ϊ Snackbar ��ʵ��
   */
  onClose?: (snackbar: Snackbar) => void;

  /**
   * Snackbar �رպ�Ļص�����������Ϊ Snackbar ��ʵ��
   */
  onClosed?: (snackbar: Snackbar) => void;
};

type STATE = 'opening' | 'opened' | 'closing' | 'closed';

const DEFAULT_OPTIONS: OPTIONS = {
  message: '',
  timeout: 4000,
  position: 'bottom',
  buttonText: '',
  buttonColor: '',
  closeOnButtonClick: true,
  closeOnOutsideClick: true,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onClick: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onButtonClick: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onOpen: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onOpened: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onClose: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onClosed: () => {},
};

/**
 * ��ǰ���ŵ� Snackbar
 */
let currentInst: null | Snackbar = null;

/**
 * ������
 */
const queueName = '_mdui_snackbar';

class Snackbar {
  /**
   * Snackbar Ԫ��
   */
  public $element: JQ;
  /**
   * ���ò���
   */
  public options: OPTIONS = extend({}, DEFAULT_OPTIONS);

  /**
   * ��ǰ Snackbar ��״̬
   */
  private state: STATE = 'closed';

  /**
   * setTimeout �� ID
   */
  private timeoutId: any = null;

  public constructor(options: OPTIONS) {
    extend(this.options, options);

    // ��ť��ɫ
    let buttonColorStyle = '';
    let buttonColorClass = '';

    if (
      this.options.buttonColor!.indexOf('#') === 0 ||
      this.options.buttonColor!.indexOf('rgb') === 0
    ) {
      buttonColorStyle = `style="color:${this.options.buttonColor}"`;
    } else if (this.options.buttonColor !== '') {
      buttonColorClass = `mdui-text-color-${this.options.buttonColor}`;
    }

    // ���� HTML
    this.$element = $(
      '<div class="mdui-snackbar">' +
        `<div class="mdui-snackbar-text">${this.options.message}</div>` +
        (this.options.buttonText
          ? `<a href="javascript:void(0)" class="mdui-snackbar-action mdui-btn mdui-ripple mdui-ripple-white ${buttonColorClass}" ${buttonColorStyle}>${this.options.buttonText}</a>`
          : '') +
        '</div>',
    ).appendTo(document.body);

    // ����λ��
    this.setPosition('close');

    this.$element.reflow().addClass(`mdui-snackbar-${this.options.position}`);
  }

  /**
   * ��� Snackbar ���������ر�
   * @param event
   */
  private closeOnOutsideClick(event: Event): void {
    const $target = $(event.target as HTMLElement);

    if (
      !$target.hasClass('mdui-snackbar') &&
      !$target.parents('.mdui-snackbar').length
    ) {
      currentInst!.close();
    }
  }

  /**
   * ���� Snackbar ��λ��
   * @param state
   */
  private setPosition(state: 'open' | 'close'): void {
    const snackbarHeight = this.$element[0].clientHeight;
    const position = this.options.position;

    let translateX;
    let translateY;

    // translateX
    if (position === 'bottom' || position === 'top') {
      translateX = '-50%';
    } else {
      translateX = '0';
    }

    // translateY
    if (state === 'open') {
      translateY = '0';
    } else {
      if (position === 'bottom') {
        translateY = snackbarHeight;
      }

      if (position === 'top') {
        translateY = -snackbarHeight;
      }

      if (position === 'left-top' || position === 'right-top') {
        translateY = -snackbarHeight - 24;
      }

      if (position === 'left-bottom' || position === 'right-bottom') {
        translateY = snackbarHeight + 24;
      }
    }

    this.$element.transform(`translate(${translateX},${translateY}px`);
  }

  /**
   * �� Snackbar
   */
  public open(): void {
    if (this.state === 'opening' || this.state === 'opened') {
      return;
    }

    // �����ǰ��������ʾ�� Snackbar�����ȼ�����У��Ⱦ� Snackbar �رպ��ٴ�
    if (currentInst) {
      queue(queueName, () => this.open());
      return;
    }

    currentInst = this;

    // ��ʼ��
    this.state = 'opening';
    this.options.onOpen!(this);

    this.setPosition('open');

    this.$element.transitionEnd(() => {
      if (this.state !== 'opening') {
        return;
      }

      this.state = 'opened';
      this.options.onOpened!(this);

      // �а�ťʱ���¼�
      if (this.options.buttonText) {
        this.$element.find('.mdui-snackbar-action').on('click', () => {
          this.options.onButtonClick!(this);
          if (this.options.closeOnButtonClick) {
            this.close();
          }
        });
      }

      // ��� snackbar ���¼�
      this.$element.on('click', (event) => {
        if (!$(event.target as HTMLElement).hasClass('mdui-snackbar-action')) {
          this.options.onClick!(this);
        }
      });

      // ��� Snackbar ���������ر�
      if (this.options.closeOnOutsideClick) {
        $document.on(startEvent, this.closeOnOutsideClick);
      }

      // ��ʱ���Զ��ر�
      if (this.options.timeout) {
        this.timeoutId = setTimeout(() => this.close(), this.options.timeout);
      }
    });
  }

  /**
   * �ر� Snackbar
   */
  public close(): void {
    if (this.state === 'closing' || this.state === 'closed') {
      return;
    }

    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
    }

    if (this.options.closeOnOutsideClick) {
      $document.off(startEvent, this.closeOnOutsideClick);
    }

    this.state = 'closing';
    this.options.onClose!(this);

    this.setPosition('close');

    this.$element.transitionEnd(() => {
      if (this.state !== 'closing') {
        return;
      }

      currentInst = null;
      this.state = 'closed';
      this.options.onClosed!(this);
      this.$element.remove();
      dequeue(queueName);
    });
  }
}

mdui.snackbar = function (message: any, options: any = {}): Snackbar {
  if (isString(message)) {
    options.message = message;
  } else {
    options = message;
  }

  const instance = new Snackbar(options);

  instance.open();

  return instance;
};
