import PlainObject from 'mdui.jq/es/interfaces/PlainObject';
import data from 'mdui.jq/es/functions/data';

type TYPE_API_INIT = (
  this: HTMLElement,
  i: number,
  element: HTMLElement,
) => void;

/**
 * CSS ѡ�����ͳ�ʼ��������ɵĶ���
 */
const entries: PlainObject<TYPE_API_INIT> = {};

/**
 * ע�Ტִ�г�ʼ������
 * @param selector CSS ѡ����
 * @param apiInit ��ʼ������
 * @param i Ԫ������
 * @param element Ԫ��
 */
function mutation(
  selector: string,
  apiInit: TYPE_API_INIT,
  i: number,
  element: HTMLElement,
): void {
  let selectors = data(element, '_mdui_mutation');

  if (!selectors) {
    selectors = [];
    data(element, '_mdui_mutation', selectors);
  }

  if (selectors.indexOf(selector) === -1) {
    selectors.push(selector);
    apiInit.call(element, i, element);
  }
}

export { TYPE_API_INIT, entries, mutation };
