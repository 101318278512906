import Selector from 'mdui.jq/es/types/Selector';
import mdui from '../../mdui';
import { CollapseAbstract, OPTIONS } from './collapseAbstract';

declare module '../../interfaces/MduiStatic' {
  interface MduiStatic {
    /**
     * �۵����ݿ����
     *
     * ��ͨ�� `new mdui.Collapse()` ����
     */
    Collapse: {
      /**
       * ʵ���� Collapse ���
       * @param selector CSS ѡ������ DOM Ԫ��
       * @param options ���ò���
       */
      new (
        selector: Selector | HTMLElement | ArrayLike<HTMLElement>,
        options?: OPTIONS,
      ): Collapse;
    };
  }
}

class Collapse extends CollapseAbstract {
  protected getNamespace(): string {
    return 'collapse';
  }
}

mdui.Collapse = Collapse;
