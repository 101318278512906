import $ from 'mdui.jq/es/$';
import extend from 'mdui.jq/es/functions/extend';
import { JQ } from 'mdui.jq/es/JQ';
import 'mdui.jq/es/methods/addClass';
import 'mdui.jq/es/methods/appendTo';
import 'mdui.jq/es/methods/attr';
import 'mdui.jq/es/methods/children';
import 'mdui.jq/es/methods/css';
import 'mdui.jq/es/methods/each';
import 'mdui.jq/es/methods/eq';
import 'mdui.jq/es/methods/first';
import 'mdui.jq/es/methods/get';
import 'mdui.jq/es/methods/hasClass';
import 'mdui.jq/es/methods/hide';
import 'mdui.jq/es/methods/index';
import 'mdui.jq/es/methods/innerWidth';
import 'mdui.jq/es/methods/offset';
import 'mdui.jq/es/methods/on';
import 'mdui.jq/es/methods/removeClass';
import 'mdui.jq/es/methods/show';
import Selector from 'mdui.jq/es/types/Selector';
import { isNumber } from 'mdui.jq/es/utils';
import mdui from '../../mdui';
import '../../jq_extends/static/throttle';
import { componentEvent } from '../../utils/componentEvent';
import { $window } from '../../utils/dom';

declare module '../../interfaces/MduiStatic' {
  interface MduiStatic {
    /**
     * Tab ѡ����
     *
     * ��ͨ�� `new mdui.Tab()` ����
     */
    Tab: {
      /**
       * ʵ���� Tab ���
       * @param selector CSS ѡ�������� DOM Ԫ�ء��� JQ ����
       * @param options ���ò���
       */
      new (
        selector: Selector | HTMLElement | ArrayLike<HTMLElement>,
        options?: OPTIONS,
      ): Tab;
    };
  }
}

type OPTIONS = {
  /**
   * �л�ѡ��Ĵ�����ʽ��`click`: ����л���`hover`: ��������л�
   */
  trigger?: 'click' | 'hover';

  /**
   * �Ƿ�����ѭ���л�����Ϊ `true`�������һ��ѡ���ʱ���� `next` �������ص���һ��ѡ���һ��ѡ���ʱ���� `prev` �������ص����һ��ѡ�
   */
  loop?: boolean;
};

type EVENT = 'change' | 'show';

const DEFAULT_OPTIONS: OPTIONS = {
  trigger: 'click',
  loop: false,
};

class Tab {
  /**
   * tab Ԫ�ص� JQ ����
   */
  public $element: JQ;

  /**
   * ���ò���
   */
  public options: OPTIONS = extend({}, DEFAULT_OPTIONS);

  /**
   * ��ǰ����� tab �������š�Ϊ -1 ʱ��ʾû�м����ѡ����򲻴���ѡ�
   */
  public activeIndex = -1;

  /**
   * ѡ������ JQ ����
   */
  private $tabs: JQ;

  /**
   * ����״̬�� tab �ײ���ָʾ��
   */
  private $indicator: JQ;

  public constructor(
    selector: Selector | HTMLElement | ArrayLike<HTMLElement>,
    options: OPTIONS = {},
  ) {
    this.$element = $(selector).first();

    extend(this.options, options);

    this.$tabs = this.$element.children('a');
    this.$indicator = $('<div class="mdui-tab-indicator"></div>').appendTo(
      this.$element,
    );

    // ���� url hash ��ȡĬ�ϼ����ѡ�
    const hash = window.location.hash;
    if (hash) {
      this.$tabs.each((index, tab) => {
        if ($(tab).attr('href') === hash) {
          this.activeIndex = index;
          return false;
        }

        return true;
      });
    }

    // �� .mdui-tab-active ��Ԫ��Ĭ�ϼ���
    if (this.activeIndex === -1) {
      this.$tabs.each((index, tab) => {
        if ($(tab).hasClass('mdui-tab-active')) {
          this.activeIndex = index;
          return false;
        }

        return true;
      });
    }

    // ����ѡ�ʱ��Ĭ�ϼ����һ��ѡ�
    if (this.$tabs.length && this.activeIndex === -1) {
      this.activeIndex = 0;
    }

    // ���ü���״̬ѡ�
    this.setActive();

    // �������ڴ�С�仯�¼�������ָʾ��λ��
    $window.on(
      'resize',
      $.throttle(() => this.setIndicatorPosition(), 100),
    );

    // �������ѡ��¼�
    this.$tabs.each((_, tab) => {
      this.bindTabEvent(tab);
    });
  }

  /**
   * ָ��ѡ��Ƿ��ѽ���
   * @param $tab
   */
  private isDisabled($tab: JQ): boolean {
    return $tab.attr('disabled') !== undefined;
  }

  /**
   * ���� Tab �ϵ�����������¼�
   * @param tab
   */
  private bindTabEvent(tab: HTMLElement): void {
    const $tab = $(tab);

    // �����������봥�����¼�
    const clickEvent = (): void | false => {
      // ����״̬��ѡ��޷�ѡ��
      if (this.isDisabled($tab)) {
        return false;
      }

      this.activeIndex = this.$tabs.index(tab);
      this.setActive();
    };

    // ���� trigger �� click ���� hover��������Ӧ click �¼�
    $tab.on('click', clickEvent);

    // trigger Ϊ hover ʱ��������Ӧ mouseenter �¼�
    if (this.options.trigger === 'hover') {
      $tab.on('mouseenter', clickEvent);
    }

    // ��ֹ���ӵ�Ĭ�ϵ������
    $tab.on('click', (): void | false => {
      if (($tab.attr('href') || '').indexOf('#') === 0) {
        return false;
      }
    });
  }

  /**
   * ��������¼�
   * @param name
   * @param $element
   * @param parameters
   */
  private triggerEvent(name: EVENT, $element: JQ, parameters = {}): void {
    componentEvent(name, 'tab', $element, this, parameters);
  }

  /**
   * ���ü���״̬��ѡ�
   */
  private setActive(): void {
    this.$tabs.each((index, tab) => {
      const $tab = $(tab);
      const targetId = $tab.attr('href') || '';

      // ����ѡ�����״̬
      if (index === this.activeIndex && !this.isDisabled($tab)) {
        if (!$tab.hasClass('mdui-tab-active')) {
          this.triggerEvent('change', this.$element, {
            index: this.activeIndex,
            id: targetId.substr(1),
          });
          this.triggerEvent('show', $tab);

          $tab.addClass('mdui-tab-active');
        }

        $(targetId).show();
        this.setIndicatorPosition();
      } else {
        $tab.removeClass('mdui-tab-active');
        $(targetId).hide();
      }
    });
  }

  /**
   * ����ѡ�ָʾ����λ��
   */
  private setIndicatorPosition(): void {
    // ѡ�����Ϊ 0 ʱ������ʾָʾ��
    if (this.activeIndex === -1) {
      this.$indicator.css({
        left: 0,
        width: 0,
      });

      return;
    }

    const $activeTab = this.$tabs.eq(this.activeIndex);

    if (this.isDisabled($activeTab)) {
      return;
    }

    const activeTabOffset = $activeTab.offset();

    this.$indicator.css({
      left: `${
        activeTabOffset.left +
        this.$element[0].scrollLeft -
        this.$element[0].getBoundingClientRect().left
      }px`,
      width: `${$activeTab.innerWidth()}px`,
    });
  }

  /**
   * �л�����һ��ѡ�
   */
  public next(): void {
    if (this.activeIndex === -1) {
      return;
    }

    if (this.$tabs.length > this.activeIndex + 1) {
      this.activeIndex++;
    } else if (this.options.loop) {
      this.activeIndex = 0;
    }

    this.setActive();
  }

  /**
   * �л�����һ��ѡ�
   */
  public prev(): void {
    if (this.activeIndex === -1) {
      return;
    }

    if (this.activeIndex > 0) {
      this.activeIndex--;
    } else if (this.options.loop) {
      this.activeIndex = this.$tabs.length - 1;
    }

    this.setActive();
  }

  /**
   * ��ʾָ�������š���ָ��id��ѡ�
   * @param index �����š���id
   */
  public show(index: number | string): void {
    if (this.activeIndex === -1) {
      return;
    }

    if (isNumber(index)) {
      this.activeIndex = index;
    } else {
      this.$tabs.each((i, tab): void | false => {
        if (tab.id === index) {
          this.activeIndex = i;
          return false;
        }
      });
    }

    this.setActive();
  }

  /**
   * �ڸ�Ԫ�صĿ��ȱ仯ʱ����Ҫ���ø÷������µ���ָʾ��λ��
   * �����ӻ�ɾ��ѡ�ʱ����Ҫ���ø÷���
   */
  public handleUpdate(): void {
    const $oldTabs = this.$tabs; // �ɵ� tabs JQ����
    const $newTabs = this.$element.children('a'); // �µ� tabs JQ����
    const oldTabsElement = $oldTabs.get(); // �ɵ� tabs Ԫ������
    const newTabsElement = $newTabs.get(); // �µ� tabs Ԫ������

    if (!$newTabs.length) {
      this.activeIndex = -1;
      this.$tabs = $newTabs;
      this.setIndicatorPosition();

      return;
    }

    // ���±���ѡ����ҳ�������ѡ�
    $newTabs.each((index, tab) => {
      // ��������ѡ�
      if (oldTabsElement.indexOf(tab) < 0) {
        this.bindTabEvent(tab);

        if (this.activeIndex === -1) {
          this.activeIndex = 0;
        } else if (index <= this.activeIndex) {
          this.activeIndex++;
        }
      }
    });

    // �ҳ����Ƴ���ѡ�
    $oldTabs.each((index, tab) => {
      // �б��Ƴ���ѡ�
      if (newTabsElement.indexOf(tab) < 0) {
        if (index < this.activeIndex) {
          this.activeIndex--;
        } else if (index === this.activeIndex) {
          this.activeIndex = 0;
        }
      }
    });

    this.$tabs = $newTabs;

    this.setActive();
  }
}

mdui.Tab = Tab;
