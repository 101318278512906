import $ from 'mdui.jq/es/$';
import { isNull } from 'mdui.jq/es/utils';

declare module 'mdui.jq/es/interfaces/JQStatic' {
  interface JQStatic {
    /**
     * ��������
     * @param fn ִ�еĺ���
     * @param delay �����ٺ���ִ��һ��
     * @example
```js
$.throttle(function () {
  console.log('���������� 100ms ִ��һ��');
}, 100)
```
     */
    throttle(fn: () => void, delay: number): () => void;
  }
}

$.throttle = function (fn: () => void, delay = 16): () => void {
  let timer: any = null;

  return function (this: any, ...args: any): void {
    if (isNull(timer)) {
      timer = setTimeout(() => {
        fn.apply(this, args);
        timer = null;
      }, delay);
    }
  };
};
