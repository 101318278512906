import extend from 'mdui.jq/es/functions/extend';
import { isFunction, isUndefined } from 'mdui.jq/es/utils';
import mdui from '../../mdui';
import { Dialog } from './class';
import './dialog';

declare module '../../interfaces/MduiStatic' {
  interface MduiStatic {
    /**
     * ��һ������򣬿��԰������⡢���ݺ�һ��ȷ�ϰ�ť
     * @param text ���������
     * @param title ��������
     * @param onConfirm ���ȷ�ϰ�ť�Ļص�����������Ϊ�Ի���ʵ��
     * @param options ���ò���
     */
    alert(
      text: string,
      title: string,
      onConfirm?: (dialog: Dialog) => void,
      options?: OPTIONS,
    ): Dialog;

    /**
     * ��һ������򣬿��԰������ݣ���һ��ȷ�ϰ�ť
     * @param text ���������
     * @param onConfirm ���ȷ�ϰ�ť�Ļص�����������Ϊ�Ի���ʵ��
     * @param options ���ò���
     */
    alert(
      text: string,
      onConfirm?: (dialog: Dialog) => void,
      options?: OPTIONS,
    ): Dialog;
  }
}

type OPTIONS = {
  /**
   * ȷ�ϰ�ť���ı�
   */
  confirmText?: string;

  /**
   * �Ƿ���� hashchange �¼���Ϊ `true` ʱ����ͨ�� Android �ķ��ؼ�����������˰�ť�رնԻ���Ĭ��Ϊ `true`
   */
  history?: boolean;

  /**
   * �Ƿ�ģ̬���Ի���Ϊ `false` ʱ����Ի������������ʱ�رնԻ��򣬷��򲻹رգ�Ĭ��Ϊ `false`
   */
  modal?: boolean;

  /**
   * ���� Esc ��ʱ�Ƿ�رնԻ���Ĭ��Ϊ `true`
   */
  closeOnEsc?: boolean;

  /**
   * �Ƿ��ڰ���ȷ�ϰ�ťʱ�Ƿ�رնԻ���
   */
  closeOnConfirm?: boolean;
};

const DEFAULT_OPTIONS: OPTIONS = {
  confirmText: 'ok',
  history: true,
  modal: false,
  closeOnEsc: true,
  closeOnConfirm: true,
};

mdui.alert = function (
  text: string,
  title?: any,
  onConfirm?: any,
  options?: any,
): Dialog {
  if (isFunction(title)) {
    options = onConfirm;
    onConfirm = title;
    title = '';
  }

  if (isUndefined(onConfirm)) {
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onConfirm = (): void => {};
  }

  if (isUndefined(options)) {
    options = {};
  }

  options = extend({}, DEFAULT_OPTIONS, options);

  return mdui.dialog({
    title: title,
    content: text,
    buttons: [
      {
        text: options.confirmText,
        bold: false,
        close: options.closeOnConfirm,
        onClick: onConfirm,
      },
    ],
    cssClass: 'mdui-dialog-alert',
    history: options.history,
    modal: options.modal,
    closeOnEsc: options.closeOnEsc,
  });
};
