import { isUndefined } from 'mdui.jq/es/utils';
import PlainObject from 'mdui.jq/es/interfaces/PlainObject';

type Func = () => any;

const container: PlainObject<Func[]> = {};

/**
 * ���ݶ���������ȡ���������к���
 * @param name ������
 */
function queue(name: string): Func[];

/**
 * д�����
 * @param name ������
 * @param func ����
 */
function queue(name: string, func: Func): void;

function queue(name: string, func?: Func): void | Func[] {
  if (isUndefined(container[name])) {
    container[name] = [];
  }

  if (isUndefined(func)) {
    return container[name];
  }

  container[name].push(func);
}

/**
 * �Ӷ������Ƴ���һ����������ִ�иú���
 * @param name ������
 */
function dequeue(name: string): void {
  if (isUndefined(container[name])) {
    return;
  }

  if (!container[name].length) {
    return;
  }

  const func = container[name].shift()!;

  func();
}

export { queue, dequeue };
