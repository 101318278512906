import $ from 'mdui.jq/es/$';
import { JQ } from 'mdui.jq/es/JQ';
import PlainObject from 'mdui.jq/es/interfaces/PlainObject';
import 'mdui.jq/es/methods/trigger';

/**
 * ��������ϵ��¼�
 * @param eventName �¼���
 * @param componentName �����
 * @param target �ڸ�Ԫ���ϴ����¼�
 * @param instance ���ʵ��
 * @param parameters �¼�����
 */
function componentEvent(
  eventName: string,
  componentName: string,
  target: HTMLElement | HTMLElement[] | JQ,
  instance?: any,
  parameters?: PlainObject,
): void {
  if (!parameters) {
    parameters = {};
  }

  // @ts-ignore
  parameters.inst = instance;

  const fullEventName = `${eventName}.mdui.${componentName}`;

  // jQuery �¼�
  // @ts-ignore
  if (typeof jQuery !== 'undefined') {
    // @ts-ignore
    jQuery(target).trigger(fullEventName, parameters);
  }

  const $target = $(target);

  // mdui.jq �¼�
  $target.trigger(fullEventName, parameters);

  // ԭ���¼�����ʹ�� addEventListener ����
  type EventParams = {
    detail?: any;
    bubbles: boolean;
    cancelable: boolean;
  };

  const eventParams: EventParams = {
    bubbles: true,
    cancelable: true,
    detail: parameters,
  };

  const eventObject: CustomEvent = new CustomEvent(fullEventName, eventParams);

  // @ts-ignore
  eventObject._detail = parameters;

  $target[0].dispatchEvent(eventObject);
}

export { componentEvent };
