import extend from 'mdui.jq/es/functions/extend';
import 'mdui.jq/es/methods/find';
import 'mdui.jq/es/methods/on';
import 'mdui.jq/es/methods/val';
import { isFunction, isUndefined } from 'mdui.jq/es/utils';
import mdui from '../../mdui';
import '../textfield';
import { Dialog } from './class';
import './dialog';

declare module '../../interfaces/MduiStatic' {
  interface MduiStatic {
    /**
     * ��һ����ʾ�û�����ĶԻ��򣬿��԰������⡢�ı����ǩ���ı���һ��ȷ�ϰ�ť��һ��ȡ����ť
     * @param label �ı��򸡶���ǩ���ı�
     * @param title ����
     * @param onConfirm ���ȷ�ϰ�ť�Ļص����������������ֱ�Ϊ�ı����ֵ�ͶԻ���ʵ��
     * @param onCancel ���ȡ����ť�Ļص����������������ֱ�Ϊ�ı����ֵ�ͶԻ���ʵ��
     * @param options ���ò���
     */
    prompt(
      label: string,
      title: string,
      onConfirm?: (value: string, dialog: Dialog) => void,
      onCancel?: (value: string, dialog: Dialog) => void,
      options?: OPTIONS,
    ): Dialog;

    /**
     * ��һ����ʾ�û�����ĶԻ��򣬿��԰����ı����ǩ���ı���һ��ȷ�ϰ�ť��һ��ȡ����ť
     * @param label �ı��򸡶���ǩ���ı�
     * @param onConfirm ���ȷ�ϰ�ť�Ļص����������������ֱ�Ϊ�ı����ֵ�ͶԻ���ʵ��
     * @param onCancel ���ȡ����ť�Ļص����������������ֱ�Ϊ�ı����ֵ�ͶԻ���ʵ��
     * @param options ���ò���
     */
    prompt(
      label: string,
      onConfirm?: (value: string, dialog: Dialog) => void,
      onCancel?: (value: string, dialog: Dialog) => void,
      options?: OPTIONS,
    ): Dialog;
  }
}

type OPTIONS = {
  /**
   * ȷ�ϰ�ť���ı�
   */
  confirmText?: string;

  /**
   * ȡ����ť���ı�
   */
  cancelText?: string;

  /**
   * �Ƿ���� hashchange �¼���Ϊ `true` ʱ����ͨ�� Android �ķ��ؼ�����������˰�ť�رնԻ���Ĭ��Ϊ `true`
   */
  history?: boolean;

  /**
   * �Ƿ�ģ̬���Ի���Ϊ `false` ʱ����Ի������������ʱ�رնԻ��򣬷��򲻹رգ�Ĭ��Ϊ `false`
   */
  modal?: boolean;

  /**
   * �Ƿ��ڰ��� Esc ��ʱ�Ƿ�رնԻ���Ĭ��Ϊ `true`
   */
  closeOnEsc?: boolean;

  /**
   * �Ƿ��ڰ���ȡ����ťʱ�Ƿ�رնԻ���
   */
  closeOnCancel?: boolean;

  /**
   * �Ƿ��ڰ���ȷ�ϰ�ťʱ�Ƿ�رնԻ���
   */
  closeOnConfirm?: boolean;

  /**
   * �Ƿ��ڰ��� Enter ��ʱ���� `onConfirm` �ص�������Ĭ��Ϊ `false`
   */
  confirmOnEnter?: boolean;

  /**
   * �ı�������͡�`text`: �����ı��� `textarea`: �����ı���
   */
  type?: 'text' | 'textarea';

  /**
   * ��������ַ�������Ϊ 0 ʱ��ʾ������
   */
  maxlength?: number;

  /**
   * �ı����Ĭ��ֵ
   */
  defaultValue?: string;
};

const DEFAULT_OPTIONS: OPTIONS = {
  confirmText: 'ok',
  cancelText: 'cancel',
  history: true,
  modal: false,
  closeOnEsc: true,
  closeOnCancel: true,
  closeOnConfirm: true,
  type: 'text',
  maxlength: 0,
  defaultValue: '',
  confirmOnEnter: false,
};

mdui.prompt = function (
  label: string,
  title?: any,
  onConfirm?: any,
  onCancel?: any,
  options?: any,
): Dialog {
  if (isFunction(title)) {
    options = onCancel;
    onCancel = onConfirm;
    onConfirm = title;
    title = '';
  }

  if (isUndefined(onConfirm)) {
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onConfirm = (): void => {};
  }

  if (isUndefined(onCancel)) {
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onCancel = (): void => {};
  }

  if (isUndefined(options)) {
    options = {};
  }

  options = extend({}, DEFAULT_OPTIONS, options);

  const content =
    '<div class="mdui-textfield">' +
    (label ? `<label class="mdui-textfield-label">${label}</label>` : '') +
    (options.type === 'text'
      ? `<input class="mdui-textfield-input" type="text" value="${
          options.defaultValue
        }" ${
          options.maxlength ? 'maxlength="' + options.maxlength + '"' : ''
        }/>`
      : '') +
    (options.type === 'textarea'
      ? `<textarea class="mdui-textfield-input" ${
          options.maxlength ? 'maxlength="' + options.maxlength + '"' : ''
        }>${options.defaultValue}</textarea>`
      : '') +
    '</div>';

  const onCancelClick = (dialog: Dialog): void => {
    const value = dialog.$element.find('.mdui-textfield-input').val();
    onCancel(value, dialog);
  };

  const onConfirmClick = (dialog: Dialog): void => {
    const value = dialog.$element.find('.mdui-textfield-input').val();
    onConfirm(value, dialog);
  };

  return mdui.dialog({
    title,
    content,
    buttons: [
      {
        text: options.cancelText,
        bold: false,
        close: options.closeOnCancel,
        onClick: onCancelClick,
      },
      {
        text: options.confirmText,
        bold: false,
        close: options.closeOnConfirm,
        onClick: onConfirmClick,
      },
    ],
    cssClass: 'mdui-dialog-prompt',
    history: options.history,
    modal: options.modal,
    closeOnEsc: options.closeOnEsc,
    onOpen: (dialog) => {
      // ��ʼ�������
      const $input = dialog.$element.find('.mdui-textfield-input');
      mdui.updateTextFields($input);

      // �۽��������
      $input[0].focus();

      // ��׽�ı���س������ڵ����ı��������´����ص�
      if (options.type !== 'textarea' && options.confirmOnEnter === true) {
        $input.on('keydown', (event) => {
          if ((event as KeyboardEvent).keyCode === 13) {
            const value = dialog.$element.find('.mdui-textfield-input').val();
            onConfirm(value, dialog);

            if (options.closeOnConfirm) {
              dialog.close();
            }

            return false;
          }

          return;
        });
      }

      // ����Ƕ�������򣬼��������� input �¼������¶Ի���߶�
      if (options.type === 'textarea') {
        $input.on('input', () => dialog.handleUpdate());
      }

      // ���ַ�������ʱ���������ı���� DOM ��仯����Ҫ���¶Ի���߶�
      if (options.maxlength) {
        dialog.handleUpdate();
      }
    },
  });
};
