import $ from 'mdui.jq/es/$';
import 'mdui.jq/es/methods/data';
import 'mdui.jq/es/methods/removeClass';
import 'mdui.jq/es/methods/width';

declare module 'mdui.jq/es/interfaces/JQStatic' {
  interface JQStatic {
    /**
     * ���ҳ������
     *
     * ��������˶�� $.lockScreen() ����ʾ���ֲ㣬��Ҳ��Ҫ������ͬ������ $.unlockScreen() �����������ֲ㡣����ͨ��������� true ��ǿ���������ֲ㡣
     * @param force �Ƿ�ǿ�ƽ������
     * @example
```js
$.unlockScreen();
```
     * @example
```js
$.unlockScreen(true);
```
     */
    unlockScreen(force?: boolean): void;
  }
}

$.unlockScreen = function (force = false): void {
  const $body = $('body');
  let level = force ? 1 : $body.data('_lockscreen_level');

  if (level > 1) {
    $body.data('_lockscreen_level', --level);
    return;
  }

  $body.data('_lockscreen_level', 0).removeClass('mdui-locked').width('');
};
