import { MduiStatic } from './interfaces/MduiStatic';
import $ from 'mdui.jq/es/$';

// ����ҳ��������ֱ��ִ��css����
// https://css-tricks.com/transitions-only-after-page-load/
setTimeout(() => $('body').addClass('mdui-loaded'));

const mdui = {
  $: $,
} as MduiStatic;

export default mdui;
