/**
 * �������ɵ�Ԫ�ؽṹΪ��
 *  <select class="mdui-select" mdui-select="{position: 'top'}" style="display: none;"> // $native
 *    <option value="1">State 1</option>
 *    <option value="2">State 2</option>
 *    <option value="3" disabled="">State 3</option>
 *  </select>
 *  <div class="mdui-select mdui-select-position-top" style="" id="88dec0e4-d4a2-c6d0-0e7f-1ba4501e0553"> // $element
 *    <span class="mdui-select-selected">State 1</span> // $selected
 *    <div class="mdui-select-menu" style="transform-origin: center 100% 0px;"> // $menu
 *      <div class="mdui-select-menu-item mdui-ripple" selected="">State 1</div> // $items
 *      <div class="mdui-select-menu-item mdui-ripple">State 2</div>
 *      <div class="mdui-select-menu-item mdui-ripple" disabled="">State 3</div>
 *    </div>
 *  </div>
 */

import $ from 'mdui.jq/es/$';
import contains from 'mdui.jq/es/functions/contains';
import extend from 'mdui.jq/es/functions/extend';
import { JQ } from 'mdui.jq/es/JQ';
import 'mdui.jq/es/methods/add';
import 'mdui.jq/es/methods/addClass';
import 'mdui.jq/es/methods/after';
import 'mdui.jq/es/methods/append';
import 'mdui.jq/es/methods/appendTo';
import 'mdui.jq/es/methods/attr';
import 'mdui.jq/es/methods/css';
import 'mdui.jq/es/methods/each';
import 'mdui.jq/es/methods/find';
import 'mdui.jq/es/methods/first';
import 'mdui.jq/es/methods/height';
import 'mdui.jq/es/methods/hide';
import 'mdui.jq/es/methods/index';
import 'mdui.jq/es/methods/innerWidth';
import 'mdui.jq/es/methods/is';
import 'mdui.jq/es/methods/on';
import 'mdui.jq/es/methods/remove';
import 'mdui.jq/es/methods/removeAttr';
import 'mdui.jq/es/methods/removeClass';
import 'mdui.jq/es/methods/show';
import 'mdui.jq/es/methods/text';
import 'mdui.jq/es/methods/trigger';
import 'mdui.jq/es/methods/val';
import Selector from 'mdui.jq/es/types/Selector';
import mdui from '../../mdui';
import '../../jq_extends/methods/transitionEnd';
import '../../jq_extends/static/guid';
import { componentEvent } from '../../utils/componentEvent';
import { $document, $window } from '../../utils/dom';

declare module '../../interfaces/MduiStatic' {
  interface MduiStatic {
    /**
     * ����ѡ�����
     *
     * ��ͨ�� `new mdui.Select()` ����
     */
    Select: {
      /**
       * ʵ���� Select ���
       * @param selector CSS ѡ�������� DOM Ԫ�ء��� JQ ����
       * @param options ���ò���
       */
      new (
        selector: Selector | HTMLElement | ArrayLike<HTMLElement>,
        options?: OPTIONS,
      ): Select;
    };
  }
}

type OPTIONS = {
  /**
   * ������λ�ã�`auto`��`top`��`bottom`
   */
  position?: 'auto' | 'top' | 'bottom';

  /**
   * �˵��봰�����±߿����ٱ��ֶ��ټ��
   */
  gutter?: number;
};

type STATE = 'closing' | 'closed' | 'opening' | 'opened';
type EVENT = 'open' | 'opened' | 'close' | 'closed';

const DEFAULT_OPTIONS: OPTIONS = {
  position: 'auto',
  gutter: 16,
};

class Select {
  /**
   * ԭ�� `<select>` Ԫ�ص� JQ ����
   */
  public $native: JQ<HTMLSelectElement>;

  /**
   * ���ɵ� `<div class="mdui-select">` Ԫ�ص� JQ ����
   */
  public $element: JQ = $();

  /**
   * ���ò���
   */
  public options: OPTIONS = extend({}, DEFAULT_OPTIONS);

  /**
   * select �� size ���Ե�ֵ�����ݸ�ֵ���� select �ĸ߶�
   */
  private size = 0;

  /**
   * ռλԪ�أ���ʾ��ѡ�в˵�����ı�
   */
  private $selected: JQ = $();

  /**
   * �˵�������Ԫ�ص� JQ ����
   */
  private $menu: JQ = $();

  /**
   * �˵�������� JQ ����
   */
  private $items: JQ = $();

  /**
   * ��ǰѡ�еĲ˵����������
   */
  private selectedIndex = 0;

  /**
   * ��ǰѡ�в˵�����ı�
   */
  private selectedText = '';

  /**
   * ��ǰѡ�в˵����ֵ
   */
  private selectedValue = '';

  /**
   * Ψһ ID
   */
  private uniqueID: string;

  /**
   * ��ǰ select ��״̬
   */
  private state: STATE = 'closed';

  public constructor(
    selector: Selector | HTMLElement | ArrayLike<HTMLElement>,
    options: OPTIONS = {},
  ) {
    this.$native = $(selector).first() as JQ<HTMLSelectElement>;
    this.$native.hide();

    extend(this.options, options);

    // Ϊ��ǰ select ����Ψһ ID
    this.uniqueID = $.guid();

    // ���� select
    this.handleUpdate();

    // ��� select ��������ر�
    $document.on('click touchstart', (event: Event) => {
      const $target = $(event.target as HTMLElement);

      if (
        this.isOpen() &&
        !$target.is(this.$element) &&
        !contains(this.$element[0], $target[0])
      ) {
        this.close();
      }
    });
  }

  /**
   * �����˵�λ��
   */
  private readjustMenu(): void {
    const windowHeight = $window.height();

    // mdui-select �߶�
    const elementHeight = this.$element.height();

    // �˵���߶�
    const $itemFirst = this.$items.first();
    const itemHeight = $itemFirst.height();
    const itemMargin = parseInt($itemFirst.css('margin-top'));

    // �˵��߶�
    const menuWidth = this.$element.innerWidth() + 0.01; // �������ʵ���ȶ�һ�㣬��Ȼ�����ʡ�Ժ�
    let menuHeight = itemHeight * this.size + itemMargin * 2;

    // mdui-select �ڴ����е�λ��
    const elementTop = this.$element[0].getBoundingClientRect().top;

    let transformOriginY: string;
    let menuMarginTop: number;

    if (this.options.position === 'bottom') {
      menuMarginTop = elementHeight;
      transformOriginY = '0px';
    } else if (this.options.position === 'top') {
      menuMarginTop = -menuHeight - 1;
      transformOriginY = '100%';
    } else {
      // �˵��߶Ȳ��ܳ������ڸ߶�
      const menuMaxHeight = windowHeight - this.options.gutter! * 2;
      if (menuHeight > menuMaxHeight) {
        menuHeight = menuMaxHeight;
      }

      // �˵��� margin-top
      menuMarginTop = -(
        itemMargin +
        this.selectedIndex * itemHeight +
        (itemHeight - elementHeight) / 2
      );

      const menuMaxMarginTop = -(
        itemMargin +
        (this.size - 1) * itemHeight +
        (itemHeight - elementHeight) / 2
      );
      if (menuMarginTop < menuMaxMarginTop) {
        menuMarginTop = menuMaxMarginTop;
      }

      // �˵����ܳ�������
      const menuTop = elementTop + menuMarginTop;
      if (menuTop < this.options.gutter!) {
        // ���ܳ��������Ϸ�
        menuMarginTop = -(elementTop - this.options.gutter!);
      } else if (menuTop + menuHeight + this.options.gutter! > windowHeight) {
        // ���ܳ��������·�
        menuMarginTop = -(
          elementTop +
          menuHeight +
          this.options.gutter! -
          windowHeight
        );
      }

      // transform �� Y ������
      transformOriginY = `${
        this.selectedIndex * itemHeight + itemHeight / 2 + itemMargin
      }px`;
    }

    // ������ʽ
    this.$element.innerWidth(menuWidth);
    this.$menu
      .innerWidth(menuWidth)
      .height(menuHeight)
      .css({
        'margin-top': menuMarginTop + 'px',
        'transform-origin': 'center ' + transformOriginY + ' 0',
      });
  }

  /**
   * select �Ƿ�Ϊ��״̬
   */
  private isOpen(): boolean {
    return this.state === 'opening' || this.state === 'opened';
  }

  /**
   * ��ԭ�� select ����������޸ĺ���Ҫ���ø÷���
   */
  public handleUpdate(): void {
    if (this.isOpen()) {
      this.close();
    }

    this.selectedValue = this.$native.val() as string;

    // ����˵������ݵ�����
    type typeItemsData = {
      value: string;
      text: string;
      disabled: boolean;
      selected: boolean;
      index: number;
    };
    const itemsData: typeItemsData[] = [];
    this.$items = $();

    // ���� HTML
    this.$native.find('option').each((index, option) => {
      const text = option.textContent || '';
      const value = option.value;
      const disabled = option.disabled;
      const selected = this.selectedValue === value;

      itemsData.push({
        value,
        text,
        disabled,
        selected,
        index,
      });

      if (selected) {
        this.selectedText = text;
        this.selectedIndex = index;
      }

      this.$items = this.$items.add(
        '<div class="mdui-select-menu-item mdui-ripple"' +
          (disabled ? ' disabled' : '') +
          (selected ? ' selected' : '') +
          `>${text}</div>`,
      );
    });

    this.$selected = $(
      `<span class="mdui-select-selected">${this.selectedText}</span>`,
    );

    this.$element = $(
      `<div class="mdui-select mdui-select-position-${this.options.position}" ` +
        `style="${this.$native.attr('style')}" ` +
        `id="${this.uniqueID}"></div>`,
    )
      .show()
      .append(this.$selected);

    this.$menu = $('<div class="mdui-select-menu"></div>')
      .appendTo(this.$element)
      .append(this.$items);

    $(`#${this.uniqueID}`).remove();
    this.$native.after(this.$element);

    // ���� select �� size �������ø߶�
    this.size = parseInt(this.$native.attr('size') || '0');

    if (this.size <= 0) {
      this.size = this.$items.length;

      if (this.size > 8) {
        this.size = 8;
      }
    }

    // ���ѡ��ʱ�ر������˵�
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const that = this;
    this.$items.on('click', function () {
      if (that.state === 'closing') {
        return;
      }

      const $item = $(this);
      const index = $item.index();
      const data = itemsData[index];

      if (data.disabled) {
        return;
      }

      that.$selected.text(data.text);
      that.$native.val(data.value);
      that.$items.removeAttr('selected');
      $item.attr('selected', '');
      that.selectedIndex = data.index;
      that.selectedValue = data.value;
      that.selectedText = data.text;
      that.$native.trigger('change');
      that.close();
    });

    // ��� $element ʱ�������˵�
    this.$element.on('click', (event: Event) => {
      const $target = $(event.target as HTMLElement);

      // �ڲ˵��ϵ��ʱ����
      if (
        $target.is('.mdui-select-menu') ||
        $target.is('.mdui-select-menu-item')
      ) {
        return;
      }

      this.toggle();
    });
  }

  /**
   * ���������Ļص�
   */
  private transitionEnd(): void {
    this.$element.removeClass('mdui-select-closing');

    if (this.state === 'opening') {
      this.state = 'opened';
      this.triggerEvent('opened');
      this.$menu.css('overflow-y', 'auto');
    }

    if (this.state === 'closing') {
      this.state = 'closed';
      this.triggerEvent('closed');

      // �ָ���ʽ
      this.$element.innerWidth('');
      this.$menu.css({
        'margin-top': '',
        height: '',
        width: '',
      });
    }
  }

  /**
   * ��������¼�
   * @param name
   */
  private triggerEvent(name: EVENT): void {
    componentEvent(name, 'select', this.$native, this);
  }

  /**
   * �л������˵��Ĵ�״̬
   */
  public toggle(): void {
    this.isOpen() ? this.close() : this.open();
  }

  /**
   * �������˵�
   */
  public open(): void {
    if (this.isOpen()) {
      return;
    }

    this.state = 'opening';
    this.triggerEvent('open');
    this.readjustMenu();
    this.$element.addClass('mdui-select-open');
    this.$menu.transitionEnd(() => this.transitionEnd());
  }

  /**
   * �ر������˵�
   */
  public close(): void {
    if (!this.isOpen()) {
      return;
    }

    this.state = 'closing';
    this.triggerEvent('close');
    this.$menu.css('overflow-y', '');
    this.$element
      .removeClass('mdui-select-open')
      .addClass('mdui-select-closing');
    this.$menu.transitionEnd(() => this.transitionEnd());
  }

  /**
   * ��ȡ��ǰ�˵���״̬������������״̬��`opening`��`opened`��`closing`��`closed`
   */
  public getState(): STATE {
    return this.state;
  }
}

mdui.Select = Select;
