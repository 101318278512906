import Selector from 'mdui.jq/es/types/Selector';
import mdui from '../../mdui';
import { CollapseAbstract, OPTIONS } from '../collapse/collapseAbstract';

declare module '../../interfaces/MduiStatic' {
  interface MduiStatic {
    /**
     * ����չ������
     *
     * ��ͨ�� `new mdui.Panel()` ����
     */
    Panel: {
      /**
       * ʵ���� Panel ���
       * @param selector CSS ѡ������ DOM Ԫ��
       * @param options ���ò���
       */
      new (
        selector: Selector | HTMLElement | ArrayLike<HTMLElement>,
        options?: OPTIONS,
      ): Panel;
    };
  }
}

class Panel extends CollapseAbstract {
  protected getNamespace(): string {
    return 'panel';
  }
}

mdui.Panel = Panel;
