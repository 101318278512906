import $ from 'mdui.jq/es/$';
import extend from 'mdui.jq/es/functions/extend';
import { JQ } from 'mdui.jq/es/JQ';
import 'mdui.jq/es/methods/addClass';
import 'mdui.jq/es/methods/children';
import 'mdui.jq/es/methods/each';
import 'mdui.jq/es/methods/eq';
import 'mdui.jq/es/methods/first';
import 'mdui.jq/es/methods/hasClass';
import 'mdui.jq/es/methods/height';
import 'mdui.jq/es/methods/is';
import 'mdui.jq/es/methods/on';
import 'mdui.jq/es/methods/parent';
import 'mdui.jq/es/methods/parents';
import 'mdui.jq/es/methods/removeClass';
import Selector from 'mdui.jq/es/types/Selector';
import { isNumber } from 'mdui.jq/es/utils';
import '../../jq_extends/methods/reflow';
import '../../jq_extends/methods/transition';
import '../../jq_extends/methods/transitionEnd';
import { componentEvent } from '../../utils/componentEvent';

type OPTIONS = {
  /**
   * �Ƿ������ַ���Ч��
   * Ϊ `true` ʱ�����ֻ����һ�������ڴ�״̬����һ�������ʱ��ر����������
   * Ϊ `false` ʱ����ͬʱ�򿪶�������
   */
  accordion?: boolean;
};

type EVENT = 'open' | 'opened' | 'close' | 'closed';

const DEFAULT_OPTIONS: OPTIONS = {
  accordion: false,
};

abstract class CollapseAbstract {
  /**
   * collapse Ԫ�ص� JQ ����
   */
  public $element: JQ;

  /**
   * ���ò���
   */
  public options: OPTIONS = extend({}, DEFAULT_OPTIONS);

  /**
   * item �� class ��
   */
  private classItem: string;

  /**
   * ��״̬�� item �� class ��
   */
  private classItemOpen: string;

  /**
   * item-header �� class ��
   */
  private classHeader: string;

  /**
   * item-body �� class ��
   */
  private classBody: string;

  /**
   * ��ȡ�̳е��������
   */
  protected abstract getNamespace(): string;

  public constructor(
    selector: Selector | HTMLElement | ArrayLike<HTMLElement>,
    options: OPTIONS = {},
  ) {
    // CSS ����
    const classPrefix = `mdui-${this.getNamespace()}-item`;
    this.classItem = classPrefix;
    this.classItemOpen = `${classPrefix}-open`;
    this.classHeader = `${classPrefix}-header`;
    this.classBody = `${classPrefix}-body`;

    this.$element = $(selector).first();

    extend(this.options, options);

    this.bindEvent();
  }

  /**
   * ���¼�
   */
  private bindEvent(): void {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const that = this;

    // ��� header ʱ����/�ر� item
    this.$element.on('click', `.${this.classHeader}`, function () {
      const $header = $(this as HTMLElement);
      const $item = $header.parent();
      const $items = that.getItems();

      $items.each((_, item) => {
        if ($item.is(item)) {
          that.toggle(item);
        }
      });
    });

    // ����رհ�ťʱ���ر� item
    this.$element.on(
      'click',
      `[mdui-${this.getNamespace()}-item-close]`,
      function () {
        const $target = $(this as HTMLElement);
        const $item = $target.parents(`.${that.classItem}`).first();

        that.close($item);
      },
    );
  }

  /**
   * ָ�� item �Ƿ��ڴ�״̬
   * @param $item
   */
  private isOpen($item: JQ): boolean {
    return $item.hasClass(this.classItemOpen);
  }

  /**
   * ��ȡ���� item
   */
  private getItems(): JQ {
    return this.$element.children(`.${this.classItem}`);
  }

  /**
   * ��ȡָ�� item
   * @param item
   */
  private getItem(
    item: number | Selector | HTMLElement | ArrayLike<HTMLElement>,
  ): JQ {
    if (isNumber(item)) {
      return this.getItems().eq(item);
    }

    return $(item).first();
  }

  /**
   * ��������¼�
   * @param name �¼���
   * @param $item �¼�������Ŀ�� item
   */
  private triggerEvent(name: EVENT, $item: JQ): void {
    componentEvent(name, this.getNamespace(), $item, this);
  }

  /**
   * ���������ص�
   * @param $content body Ԫ��
   * @param $item item Ԫ��
   */
  private transitionEnd($content: JQ, $item: JQ): void {
    if (this.isOpen($item)) {
      $content.transition(0).height('auto').reflow().transition('');

      this.triggerEvent('opened', $item);
    } else {
      $content.height('');

      this.triggerEvent('closed', $item);
    }
  }

  /**
   * ��ָ�������
   * @param item �����������š��� CSS ѡ�������� DOM Ԫ�ء��� JQ ����
   */
  public open(
    item: number | Selector | HTMLElement | ArrayLike<HTMLElement>,
  ): void {
    const $item = this.getItem(item);

    if (this.isOpen($item)) {
      return;
    }

    // �ر�������
    if (this.options.accordion) {
      this.$element.children(`.${this.classItemOpen}`).each((_, element) => {
        const $element = $(element);

        if (!$element.is($item)) {
          this.close($element);
        }
      });
    }

    const $content = $item.children(`.${this.classBody}`);

    $content
      .height($content[0].scrollHeight)
      .transitionEnd(() => this.transitionEnd($content, $item));

    this.triggerEvent('open', $item);

    $item.addClass(this.classItemOpen);
  }

  /**
   * �ر�ָ�������
   * @param item �����������š��� CSS ѡ�������� DOM Ԫ�ء��� JQ ����
   */
  public close(
    item: number | Selector | HTMLElement | ArrayLike<HTMLElement>,
  ): void {
    const $item = this.getItem(item);

    if (!this.isOpen($item)) {
      return;
    }

    const $content = $item.children(`.${this.classBody}`);

    this.triggerEvent('close', $item);

    $item.removeClass(this.classItemOpen);

    $content
      .transition(0)
      .height($content[0].scrollHeight)
      .reflow()
      .transition('')
      .height('')
      .transitionEnd(() => this.transitionEnd($content, $item));
  }

  /**
   * �л�ָ�������Ĵ�״̬
   * @param item �����������š��� CSS ѡ�������� DOM Ԫ�ء��� JQ ����
   */
  public toggle(
    item: number | Selector | HTMLElement | ArrayLike<HTMLElement>,
  ): void {
    const $item = this.getItem(item);

    this.isOpen($item) ? this.close($item) : this.open($item);
  }

  /**
   * �����������
   */
  public openAll(): void {
    this.getItems().each((_, element) => this.open(element));
  }

  /**
   * �ر����������
   */
  public closeAll(): void {
    this.getItems().each((_, element) => this.close(element));
  }
}

export { OPTIONS, CollapseAbstract };
