import $ from 'mdui.jq/es/$';
import contains from 'mdui.jq/es/functions/contains';
import extend from 'mdui.jq/es/functions/extend';
import { JQ } from 'mdui.jq/es/JQ';
import 'mdui.jq/es/methods/addClass';
import 'mdui.jq/es/methods/append';
import 'mdui.jq/es/methods/children';
import 'mdui.jq/es/methods/css';
import 'mdui.jq/es/methods/each';
import 'mdui.jq/es/methods/find';
import 'mdui.jq/es/methods/first';
import 'mdui.jq/es/methods/hasClass';
import 'mdui.jq/es/methods/height';
import 'mdui.jq/es/methods/hide';
import 'mdui.jq/es/methods/innerHeight';
import 'mdui.jq/es/methods/off';
import 'mdui.jq/es/methods/on';
import 'mdui.jq/es/methods/remove';
import 'mdui.jq/es/methods/removeClass';
import 'mdui.jq/es/methods/show';
import Selector from 'mdui.jq/es/types/Selector';
import '../../jq_extends/methods/transitionEnd';
import '../../jq_extends/static/hideOverlay';
import '../../jq_extends/static/lockScreen';
import '../../jq_extends/static/showOverlay';
import '../../jq_extends/static/throttle';
import '../../jq_extends/static/unlockScreen';
import { componentEvent } from '../../utils/componentEvent';
import { $window } from '../../utils/dom';
import { dequeue, queue } from '../../utils/queue';

type OPTIONS = {
  /**
   * �򿪶Ի���ʱ�Ƿ����� url hash����Ϊ `true`����򿪶Ի������ù�������ĺ��˰�ť�� Android �ķ��ؼ��رնԻ���
   */
  history?: boolean;

  /**
   * �򿪶Ի���ʱ�Ƿ���ʾ���֡�
   */
  overlay?: boolean;

  /**
   * �Ƿ�ģ̬���Ի���Ϊ `false` ʱ����Ի������������ʱ�رնԻ��򣬷��򲻹رա�
   */
  modal?: boolean;

  /**
   * ���� Esc ��ʱ�Ƿ�رնԻ���
   */
  closeOnEsc?: boolean;

  /**
   * ����ȡ����ťʱ�Ƿ�رնԻ���
   */
  closeOnCancel?: boolean;

  /**
   * ����ȷ�ϰ�ťʱ�Ƿ�رնԻ���
   */
  closeOnConfirm?: boolean;

  /**
   * �رնԻ�����Ƿ��Զ����ٶԻ���
   */
  destroyOnClosed?: boolean;
};

type STATE = 'opening' | 'opened' | 'closing' | 'closed';
type EVENT = 'open' | 'opened' | 'close' | 'closed' | 'cancel' | 'confirm';

const DEFAULT_OPTIONS: OPTIONS = {
  history: true,
  overlay: true,
  modal: false,
  closeOnEsc: true,
  closeOnCancel: true,
  closeOnConfirm: true,
  destroyOnClosed: false,
};

/**
 * ��ǰ��ʾ�ĶԻ���ʵ��
 */
let currentInst: null | Dialog = null;

/**
 * ������
 */
const queueName = '_mdui_dialog';

/**
 * �����Ƿ�������
 */
let isLockScreen = false;

/**
 * ���ֲ�Ԫ��
 */
let $overlay: null | JQ;

class Dialog {
  /**
   * dialog Ԫ�ص� JQ ����
   */
  public $element: JQ;

  /**
   * ���ò���
   */
  public options: OPTIONS = extend({}, DEFAULT_OPTIONS);

  /**
   * ��ǰ dialog ��״̬
   */
  public state: STATE = 'closed';

  /**
   * dialog Ԫ���Ƿ��Ƕ�̬���ӵ�
   */
  private append = false;

  public constructor(
    selector: Selector | HTMLElement | ArrayLike<HTMLElement>,
    options: OPTIONS = {},
  ) {
    this.$element = $(selector).first();

    // ����Ի���Ԫ��û���ڵ�ǰ�ĵ��У�����Ҫ����
    if (!contains(document.body, this.$element[0])) {
      this.append = true;
      $('body').append(this.$element);
    }

    extend(this.options, options);

    // ��ȡ����ť�¼�
    this.$element.find('[mdui-dialog-cancel]').each((_, cancel) => {
      $(cancel).on('click', () => {
        this.triggerEvent('cancel');

        if (this.options.closeOnCancel) {
          this.close();
        }
      });
    });

    // ��ȷ�ϰ�ť�¼�
    this.$element.find('[mdui-dialog-confirm]').each((_, confirm) => {
      $(confirm).on('click', () => {
        this.triggerEvent('confirm');

        if (this.options.closeOnConfirm) {
          this.close();
        }
      });
    });

    // �󶨹رհ�ť�¼�
    this.$element.find('[mdui-dialog-close]').each((_, close) => {
      $(close).on('click', () => this.close());
    });
  }

  /**
   * ��������¼�
   * @param name
   */
  private triggerEvent(name: EVENT): void {
    componentEvent(name, 'dialog', this.$element, this);
  }

  /**
   * ���ڿ��ȱ仯����Ի������ݱ仯ʱ�������Ի���λ�úͶԻ����ڵĹ�����
   */
  private readjust(): void {
    if (!currentInst) {
      return;
    }

    const $element = currentInst.$element;
    const $title = $element.children('.mdui-dialog-title');
    const $content = $element.children('.mdui-dialog-content');
    const $actions = $element.children('.mdui-dialog-actions');

    // ���� dialog �� top �� height ֵ
    $element.height('');
    $content.height('');

    const elementHeight = $element.height();
    $element.css({
      top: `${($window.height() - elementHeight) / 2}px`,
      height: `${elementHeight}px`,
    });

    // ���� mdui-dialog-content �ĸ߶�
    $content.innerHeight(
      elementHeight -
        ($title.innerHeight() || 0) -
        ($actions.innerHeight() || 0),
    );
  }

  /**
   * hashchange �¼�����ʱ�رնԻ���
   */
  private hashchangeEvent(): void {
    if (window.location.hash.substring(1).indexOf('mdui-dialog') < 0) {
      currentInst!.close(true);
    }
  }

  /**
   * ������ֲ�رնԻ���
   * @param event
   */
  private overlayClick(event: Event): void {
    if (
      $(event.target as HTMLElement).hasClass('mdui-overlay') &&
      currentInst
    ) {
      currentInst.close();
    }
  }

  /**
   * ���������ص�
   */
  private transitionEnd(): void {
    if (this.$element.hasClass('mdui-dialog-open')) {
      this.state = 'opened';
      this.triggerEvent('opened');
    } else {
      this.state = 'closed';
      this.triggerEvent('closed');
      this.$element.hide();

      // ���жԻ��򶼹رգ��ҵ�ǰû�д򿪵ĶԻ���ʱ��������Ļ
      if (!queue(queueName).length && !currentInst && isLockScreen) {
        $.unlockScreen();
        isLockScreen = false;
      }

      $window.off('resize', $.throttle(this.readjust, 100));

      if (this.options.destroyOnClosed) {
        this.destroy();
      }
    }
  }

  /**
   * ��ָ���Ի���
   */
  private doOpen(): void {
    currentInst = this;

    if (!isLockScreen) {
      $.lockScreen();
      isLockScreen = true;
    }

    this.$element.show();
    this.readjust();

    $window.on('resize', $.throttle(this.readjust, 100));

    // ����Ϣ��
    this.state = 'opening';
    this.triggerEvent('open');
    this.$element
      .addClass('mdui-dialog-open')
      .transitionEnd(() => this.transitionEnd());

    // ���������ֲ�Ԫ��ʱ���������ֲ�
    if (!$overlay) {
      $overlay = $.showOverlay(5100);
    }

    // ������ֲ�ʱ�Ƿ�رնԻ���
    if (this.options.modal) {
      $overlay.off('click', this.overlayClick);
    } else {
      $overlay.on('click', this.overlayClick);
    }

    // �Ƿ���ʾ���ֲ㣬����ʾʱ�������ֲ㱳��͸��
    $overlay.css('opacity', this.options.overlay ? '' : 0);

    if (this.options.history) {
      // ��� hash ��ԭ������ mdui-dialog����ɾ�������������ʷ��¼����Ȼ�� mdui-dialog �����޷��ر�
      // ���� mdui-dialog �� &mdui-dialog �� ?mdui-dialog
      let hash = window.location.hash.substring(1);
      if (hash.indexOf('mdui-dialog') > -1) {
        hash = hash.replace(/[&?]?mdui-dialog/g, '');
      }

      // ���˰�ť�رնԻ���
      if (hash) {
        window.location.hash = `${hash}${
          hash.indexOf('?') > -1 ? '&' : '?'
        }mdui-dialog`;
      } else {
        window.location.hash = 'mdui-dialog';
      }

      $window.on('hashchange', this.hashchangeEvent);
    }
  }

  /**
   * ��ǰ�Ի����Ƿ�Ϊ��״̬
   */
  private isOpen(): boolean {
    return this.state === 'opening' || this.state === 'opened';
  }

  /**
   * �򿪶Ի���
   */
  public open(): void {
    if (this.isOpen()) {
      return;
    }

    // �����ǰ�����ڴ򿪻��Ѿ��򿪵ĶԻ���,����в�Ϊ�գ����ȼ�����У��ȾɶԻ���ʼ�ر�ʱ�ٴ�
    if (
      (currentInst &&
        (currentInst.state === 'opening' || currentInst.state === 'opened')) ||
      queue(queueName).length
    ) {
      queue(queueName, () => this.doOpen());

      return;
    }

    this.doOpen();
  }

  /**
   * �رնԻ���
   */
  public close(historyBack = false): void {
    // historyBack �Ƿ���Ҫ������ʷ��¼��Ĭ��Ϊ `false`���ò������ڲ�ʹ��
    // Ϊ `false` ʱ��ͨ�� js �رգ���Ҫ����һ����ʷ��¼
    // Ϊ `true` ʱ��ͨ�����˰�ť�رգ�����Ҫ������ʷ��¼

    // setTimeout �������ǣ�
    // ��ͬʱ�ر�һ���Ի��򣬲�����һ���Ի���ʱ��ʹ�򿪶Ի���Ĳ�����ִ�У���ʹ��Ҫ�򿪵ĶԻ����ȼ������
    setTimeout(() => {
      if (!this.isOpen()) {
        return;
      }

      currentInst = null;

      this.state = 'closing';
      this.triggerEvent('close');

      // ���жԻ��򶼹رգ��ҵ�ǰû�д򿪵ĶԻ���ʱ����������
      if (!queue(queueName).length && $overlay) {
        $.hideOverlay();
        $overlay = null;

        // ���Դ������֣��ָ����ֵ� z-index
        $('.mdui-overlay').css('z-index', 2000);
      }

      this.$element
        .removeClass('mdui-dialog-open')
        .transitionEnd(() => this.transitionEnd());

      if (this.options.history && !queue(queueName).length) {
        if (!historyBack) {
          window.history.back();
        }

        $window.off('hashchange', this.hashchangeEvent);
      }

      // �رվɶԻ��򣬴��¶Ի���
      // ��һ���ӳ٣�����Ϊ���Ӿ�Ч�����á�������ʱҲ��Ӱ�칦��
      setTimeout(() => {
        dequeue(queueName);
      }, 100);
    });
  }

  /**
   * �л��Ի����/�ر�״̬
   */
  public toggle(): void {
    this.isOpen() ? this.close() : this.open();
  }

  /**
   * ��ȡ�Ի���״̬������������״̬��`opening`��`opened`��`closing`��`closed`
   */
  public getState(): STATE {
    return this.state;
  }

  /**
   * ���ٶԻ���
   */
  public destroy(): void {
    if (this.append) {
      this.$element.remove();
    }

    if (!queue(queueName).length && !currentInst) {
      if ($overlay) {
        $.hideOverlay();
        $overlay = null;
      }

      if (isLockScreen) {
        $.unlockScreen();
        isLockScreen = false;
      }
    }
  }

  /**
   * �Ի������ݱ仯ʱ����Ҫ���ø÷����������Ի���λ�ú͹������߶�
   */
  public handleUpdate(): void {
    this.readjust();
  }
}

export { currentInst, OPTIONS, Dialog };
