import $ from 'mdui.jq/es/$';
import extend from 'mdui.jq/es/functions/extend';
import { JQ } from 'mdui.jq/es/JQ';
import 'mdui.jq/es/methods/addClass';
import 'mdui.jq/es/methods/data';
import 'mdui.jq/es/methods/first';
import 'mdui.jq/es/methods/hasClass';
import 'mdui.jq/es/methods/on';
import 'mdui.jq/es/methods/removeClass';
import Selector from 'mdui.jq/es/types/Selector';
import { isNumber } from 'mdui.jq/es/utils';
import mdui from '../../mdui';
import '../../jq_extends/methods/transitionEnd';
import { componentEvent } from '../../utils/componentEvent';
import { $window } from '../../utils/dom';

declare module '../../interfaces/MduiStatic' {
  interface MduiStatic {
    /**
     * Headroom ���
     *
     * ��ͨ�� `new mdui.Headroom()` ����
     */
    Headroom: {
      /**
       * ʵ���� Headroom ���
       * @param selector CSS ѡ�������� DOM Ԫ�ء��� JQ ����
       * @param options ���ò���
       */
      new (
        selector: Selector | HTMLElement | ArrayLike<HTMLElement>,
        options?: OPTIONS,
      ): Headroom;
    };
  }
}

type TOLERANCE = {
  /**
   * ���������¹������پ��뿪ʼ���ػ���ʾԪ��
   */
  down: number;

  /**
   * ���������Ϲ������پ��뿪ʼ���ػ���ʾԪ��
   */
  up: number;
};

type OPTIONS = {
  /**
   * �������������پ��뿪ʼ���ػ���ʾԪ��
   */
  tolerance?: TOLERANCE | number;

  /**
   * ��ҳ�涥�����پ����ڹ�����������Ԫ��
   */
  offset?: number;

  /**
   * ��ʼ��ʱ���ӵ���
   */
  initialClass?: string;

  /**
   * Ԫ�ع̶�ʱ���ӵ���
   */
  pinnedClass?: string;

  /**
   * Ԫ������ʱ���ӵ���
   */
  unpinnedClass?: string;
};

type STATE = 'pinning' | 'pinned' | 'unpinning' | 'unpinned';
type EVENT = 'pin' | 'pinned' | 'unpin' | 'unpinned';

const DEFAULT_OPTIONS: OPTIONS = {
  tolerance: 5,
  offset: 0,
  initialClass: 'mdui-headroom',
  pinnedClass: 'mdui-headroom-pinned-top',
  unpinnedClass: 'mdui-headroom-unpinned-top',
};

class Headroom {
  /**
   * headroom Ԫ�ص� JQ ����
   */
  public $element: JQ;

  /**
   * ���ò���
   */
  public options: OPTIONS = extend({}, DEFAULT_OPTIONS);

  /**
   * ��ǰ headroom ��״̬
   */
  private state: STATE = 'pinned';

  /**
   * ��ǰ�Ƿ�����
   */
  private isEnable = false;

  /**
   * �ϴι����󣬴�ֱ����ľ���
   */
  private lastScrollY = 0;

  /**
   * AnimationFrame ID
   */
  private rafId = 0;

  public constructor(
    selector: Selector | HTMLElement | ArrayLike<HTMLElement>,
    options: OPTIONS = {},
  ) {
    this.$element = $(selector).first();

    extend(this.options, options);

    // tolerance ������Ϊ��ֵ��ת��Ϊ����
    const tolerance = this.options.tolerance;
    if (isNumber(tolerance)) {
      this.options.tolerance = {
        down: tolerance,
        up: tolerance,
      };
    }

    this.enable();
  }

  /**
   * ����ʱ�Ĵ���
   */
  private onScroll(): void {
    this.rafId = window.requestAnimationFrame(() => {
      const currentScrollY = window.pageYOffset;
      const direction = currentScrollY > this.lastScrollY ? 'down' : 'up';
      const tolerance = (this.options.tolerance as TOLERANCE)[direction];
      const scrolled = Math.abs(currentScrollY - this.lastScrollY);
      const toleranceExceeded = scrolled >= tolerance;

      if (
        currentScrollY > this.lastScrollY &&
        currentScrollY >= this.options.offset! &&
        toleranceExceeded
      ) {
        this.unpin();
      } else if (
        (currentScrollY < this.lastScrollY && toleranceExceeded) ||
        currentScrollY <= this.options.offset!
      ) {
        this.pin();
      }

      this.lastScrollY = currentScrollY;
    });
  }

  /**
   * ��������¼�
   * @param name
   */
  private triggerEvent(name: EVENT): void {
    componentEvent(name, 'headroom', this.$element, this);
  }

  /**
   * ���������Ļص�
   */
  private transitionEnd(): void {
    if (this.state === 'pinning') {
      this.state = 'pinned';
      this.triggerEvent('pinned');
    }

    if (this.state === 'unpinning') {
      this.state = 'unpinned';
      this.triggerEvent('unpinned');
    }
  }

  /**
   * ʹԪ�ع̶�ס
   */
  public pin(): void {
    if (
      this.state === 'pinning' ||
      this.state === 'pinned' ||
      !this.$element.hasClass(this.options.initialClass!)
    ) {
      return;
    }

    this.triggerEvent('pin');
    this.state = 'pinning';
    this.$element
      .removeClass(this.options.unpinnedClass)
      .addClass(this.options.pinnedClass!)
      .transitionEnd(() => this.transitionEnd());
  }

  /**
   * ʹԪ������
   */
  public unpin(): void {
    if (
      this.state === 'unpinning' ||
      this.state === 'unpinned' ||
      !this.$element.hasClass(this.options.initialClass!)
    ) {
      return;
    }

    this.triggerEvent('unpin');
    this.state = 'unpinning';
    this.$element
      .removeClass(this.options.pinnedClass)
      .addClass(this.options.unpinnedClass!)
      .transitionEnd(() => this.transitionEnd());
  }

  /**
   * ���� headroom ���
   */
  public enable(): void {
    if (this.isEnable) {
      return;
    }

    this.isEnable = true;
    this.state = 'pinned';
    this.$element
      .addClass(this.options.initialClass!)
      .removeClass(this.options.pinnedClass)
      .removeClass(this.options.unpinnedClass);
    this.lastScrollY = window.pageYOffset;

    $window.on('scroll', () => this.onScroll());
  }

  /**
   * ���� headroom ���
   */
  public disable(): void {
    if (!this.isEnable) {
      return;
    }

    this.isEnable = false;
    this.$element
      .removeClass(this.options.initialClass)
      .removeClass(this.options.pinnedClass)
      .removeClass(this.options.unpinnedClass);

    $window.off('scroll', () => this.onScroll());
    window.cancelAnimationFrame(this.rafId);
  }

  /**
   * ��ȡ��ǰ״̬������������״̬��`pinning`��`pinned`��`unpinning`��`unpinned`
   */
  public getState(): STATE {
    return this.state;
  }
}

mdui.Headroom = Headroom;
