/**
 * �������豸��Ĭ����ʾ������������ʾ���ֲ�
 * ���ֻ���ƽ���豸��Ĭ�ϲ���ʾ��������ʼ����ʾ���ֲ㣬�Ҹ��ǵ�����
 */

import $ from 'mdui.jq/es/$';
import extend from 'mdui.jq/es/functions/extend';
import { JQ } from 'mdui.jq/es/JQ';
import 'mdui.jq/es/methods/addClass';
import 'mdui.jq/es/methods/each';
import 'mdui.jq/es/methods/find';
import 'mdui.jq/es/methods/first';
import 'mdui.jq/es/methods/hasClass';
import 'mdui.jq/es/methods/off';
import 'mdui.jq/es/methods/on';
import 'mdui.jq/es/methods/one';
import 'mdui.jq/es/methods/removeClass';
import 'mdui.jq/es/methods/width';
import Selector from 'mdui.jq/es/types/Selector';
import mdui from '../../mdui';
import '../../jq_extends/methods/transitionEnd';
import '../../jq_extends/static/hideOverlay';
import '../../jq_extends/static/lockScreen';
import '../../jq_extends/static/showOverlay';
import '../../jq_extends/static/throttle';
import '../../jq_extends/static/unlockScreen';
import { componentEvent } from '../../utils/componentEvent';
import { $window } from '../../utils/dom';

declare module '../../interfaces/MduiStatic' {
  interface MduiStatic {
    /**
     * Drawer ���
     *
     * ��ͨ�� `new mdui.Drawer()` ����
     */
    Drawer: {
      /**
       * ʵ���� Drawer ���
       * @param selector CSS ѡ�������� DOM Ԫ�ء��� JQ ����
       * @param options ���ò���
       */
      new (
        selector: Selector | HTMLElement | ArrayLike<HTMLElement>,
        options?: OPTIONS,
      ): Drawer;
    };
  }
}

type OPTIONS = {
  /**
   * �򿪳�����ʱ�Ƿ���ʾ���ֲ㡣�ò���ֻ���е���Ļ�����ϵ��豸��Ч���ڳ�С����С���豸��ʼ�ջ���ʾ���ֲ㡣
   */
  overlay?: boolean;

  /**
   * �Ƿ����û������ơ�
   */
  swipe?: boolean;
};

type STATE = 'opening' | 'opened' | 'closing' | 'closed';
type EVENT = 'open' | 'opened' | 'close' | 'closed';

const DEFAULT_OPTIONS: OPTIONS = {
  overlay: false,
  swipe: false,
};

class Drawer {
  /**
   * drawer Ԫ�ص� JQ ����
   */
  public $element: JQ;

  /**
   * ���ò���
   */
  public options: OPTIONS = extend({}, DEFAULT_OPTIONS);

  /**
   * ��ǰ�Ƿ���ʾ�����ֲ�
   */
  private overlay = false;

  /**
   * ��������λ��
   */
  private position: 'left' | 'right';

  /**
   * ��ǰ������״̬
   */
  private state: STATE;

  public constructor(
    selector: Selector | HTMLElement | ArrayLike<HTMLElement>,
    options: OPTIONS = {},
  ) {
    this.$element = $(selector).first();

    extend(this.options, options);

    this.position = this.$element.hasClass('mdui-drawer-right')
      ? 'right'
      : 'left';

    if (this.$element.hasClass('mdui-drawer-close')) {
      this.state = 'closed';
    } else if (this.$element.hasClass('mdui-drawer-open')) {
      this.state = 'opened';
    } else if (this.isDesktop()) {
      this.state = 'opened';
    } else {
      this.state = 'closed';
    }

    // ��������ڴ�С����ʱ
    $window.on(
      'resize',
      $.throttle(() => {
        if (this.isDesktop()) {
          // ���ֻ�ƽ���л�������ʱ
          // �����ʾ�����֣�����������
          if (this.overlay && !this.options.overlay) {
            $.hideOverlay();
            this.overlay = false;
            $.unlockScreen();
          }

          // û��ǿ�ƹرգ���״̬Ϊ��״̬
          if (!this.$element.hasClass('mdui-drawer-close')) {
            this.state = 'opened';
          }
        } else if (!this.overlay && this.state === 'opened') {
          // �������л����ֻ�ƽ��ʱ������������Ǵ��ŵ���û�����ֲ㣬��رճ�����
          if (this.$element.hasClass('mdui-drawer-open')) {
            $.showOverlay();
            this.overlay = true;
            $.lockScreen();

            $('.mdui-overlay').one('click', () => this.close());
          } else {
            this.state = 'closed';
          }
        }
      }, 100),
    );

    // �󶨹رհ�ť�¼�
    this.$element.find('[mdui-drawer-close]').each((_, close) => {
      $(close).on('click', () => this.close());
    });

    this.swipeSupport();
  }

  /**
   * �Ƿ��������豸
   */
  private isDesktop(): boolean {
    return $window.width() >= 1024;
  }

  /**
   * ��������֧��
   */
  private swipeSupport(): void {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const that = this;

    // �������������ƿ���
    let openNavEventHandler: (event: Event) => void;
    let touchStartX: number;
    let touchStartY: number;
    let swipeStartX: number;
    let swiping: null | 'opening' | 'closing' = null;
    let maybeSwiping = false;
    const $body = $('body');

    // ���ƴ����ķ�Χ
    const swipeAreaWidth = 24;

    function setPosition(translateX: number): void {
      const rtlTranslateMultiplier = that.position === 'right' ? -1 : 1;
      const transformCSS = `translate(${
        -1 * rtlTranslateMultiplier * translateX
      }px, 0) !important;`;
      const transitionCSS = 'initial !important;';

      that.$element.css(
        'cssText',
        `transform: ${transformCSS}; transition: ${transitionCSS};`,
      );
    }

    function cleanPosition(): void {
      that.$element[0].style.transform = '';
      that.$element[0].style.webkitTransform = '';
      that.$element[0].style.transition = '';
      that.$element[0].style.webkitTransition = '';
    }

    function getMaxTranslateX(): number {
      return that.$element.width() + 10;
    }

    function getTranslateX(currentX: number): number {
      return Math.min(
        Math.max(
          swiping === 'closing'
            ? swipeStartX - currentX
            : getMaxTranslateX() + swipeStartX - currentX,
          0,
        ),
        getMaxTranslateX(),
      );
    }

    function onBodyTouchEnd(event?: Event): void {
      if (swiping) {
        let touchX = (event as TouchEvent).changedTouches[0].pageX;
        if (that.position === 'right') {
          touchX = $body.width() - touchX;
        }

        const translateRatio = getTranslateX(touchX) / getMaxTranslateX();

        maybeSwiping = false;
        const swipingState = swiping;
        swiping = null;

        if (swipingState === 'opening') {
          if (translateRatio < 0.92) {
            cleanPosition();
            that.open();
          } else {
            cleanPosition();
          }
        } else {
          if (translateRatio > 0.08) {
            cleanPosition();
            that.close();
          } else {
            cleanPosition();
          }
        }

        $.unlockScreen();
      } else {
        maybeSwiping = false;
      }

      $body.off({
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        touchmove: onBodyTouchMove,
        touchend: onBodyTouchEnd,
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        touchcancel: onBodyTouchMove,
      });
    }

    function onBodyTouchMove(event: Event): void {
      let touchX = (event as TouchEvent).touches[0].pageX;
      if (that.position === 'right') {
        touchX = $body.width() - touchX;
      }

      const touchY = (event as TouchEvent).touches[0].pageY;

      if (swiping) {
        setPosition(getTranslateX(touchX));
      } else if (maybeSwiping) {
        const dXAbs = Math.abs(touchX - touchStartX);
        const dYAbs = Math.abs(touchY - touchStartY);
        const threshold = 8;

        if (dXAbs > threshold && dYAbs <= threshold) {
          swipeStartX = touchX;
          swiping = that.state === 'opened' ? 'closing' : 'opening';
          $.lockScreen();
          setPosition(getTranslateX(touchX));
        } else if (dXAbs <= threshold && dYAbs > threshold) {
          onBodyTouchEnd();
        }
      }
    }

    function onBodyTouchStart(event: Event): void {
      touchStartX = (event as TouchEvent).touches[0].pageX;
      if (that.position === 'right') {
        touchStartX = $body.width() - touchStartX;
      }

      touchStartY = (event as TouchEvent).touches[0].pageY;

      if (that.state !== 'opened') {
        if (
          touchStartX > swipeAreaWidth ||
          openNavEventHandler !== onBodyTouchStart
        ) {
          return;
        }
      }

      maybeSwiping = true;

      $body.on({
        touchmove: onBodyTouchMove,
        touchend: onBodyTouchEnd,
        touchcancel: onBodyTouchMove,
      });
    }

    function enableSwipeHandling(): void {
      if (!openNavEventHandler) {
        $body.on('touchstart', onBodyTouchStart);
        openNavEventHandler = onBodyTouchStart;
      }
    }

    if (this.options.swipe) {
      enableSwipeHandling();
    }
  }

  /**
   * ��������¼�
   * @param name
   */
  private triggerEvent(name: EVENT): void {
    componentEvent(name, 'drawer', this.$element, this);
  }

  /**
   * ���������ص�
   */
  private transitionEnd(): void {
    if (this.$element.hasClass('mdui-drawer-open')) {
      this.state = 'opened';
      this.triggerEvent('opened');
    } else {
      this.state = 'closed';
      this.triggerEvent('closed');
    }
  }

  /**
   * �Ƿ��ڴ�״̬
   */
  private isOpen(): boolean {
    return this.state === 'opening' || this.state === 'opened';
  }

  /**
   * �򿪳�����
   */
  public open(): void {
    if (this.isOpen()) {
      return;
    }

    this.state = 'opening';
    this.triggerEvent('open');

    if (!this.options.overlay) {
      $('body').addClass(`mdui-drawer-body-${this.position}`);
    }

    this.$element
      .removeClass('mdui-drawer-close')
      .addClass('mdui-drawer-open')
      .transitionEnd(() => this.transitionEnd());

    if (!this.isDesktop() || this.options.overlay) {
      this.overlay = true;
      $.showOverlay().one('click', () => this.close());
      $.lockScreen();
    }
  }

  /**
   * �رճ�����
   */
  public close(): void {
    if (!this.isOpen()) {
      return;
    }

    this.state = 'closing';
    this.triggerEvent('close');

    if (!this.options.overlay) {
      $('body').removeClass(`mdui-drawer-body-${this.position}`);
    }

    this.$element
      .addClass('mdui-drawer-close')
      .removeClass('mdui-drawer-open')
      .transitionEnd(() => this.transitionEnd());

    if (this.overlay) {
      $.hideOverlay();
      this.overlay = false;
      $.unlockScreen();
    }
  }

  /**
   * �л���������/�ر�״̬
   */
  public toggle(): void {
    this.isOpen() ? this.close() : this.open();
  }

  /**
   * ���ص�ǰ��������״̬������������״̬��`opening`��`opened`��`closing`��`closed`
   */
  public getState(): STATE {
    return this.state;
  }
}

mdui.Drawer = Drawer;
