import $ from 'mdui.jq/es/$';
import 'mdui.jq/es/methods/each';
import 'mdui.jq/es/methods/hasClass';
import 'mdui.jq/es/methods/html';
import Selector from 'mdui.jq/es/types/Selector';
import { isUndefined } from 'mdui.jq/es/utils';
import mdui from '../../mdui';
import '../../global/mutation';

declare module '../../interfaces/MduiStatic' {
  interface MduiStatic {
    /**
     * �����Ҫ�޸����е�Բ�ν������������Ҫ���ø÷��������³�ʼ�������
     *
     * �������˲�������ֻ���³�ʼ���ò�����Ӧ��Բ�ν���������û�д�������������³�ʼ������Բ�ν�������
     * @param selector CSS ѡ�������� DOM Ԫ�ء��� DOM Ԫ����ɵ����顢�� JQ ����
     */
    updateSpinners(
      selector?: Selector | HTMLElement | ArrayLike<HTMLElement>,
    ): void;
  }
}

/**
 * layer �� HTML �ṹ
 * @param index
 */
function layerHTML(index: number | false = false): string {
  return (
    `<div class="mdui-spinner-layer ${
      index ? `mdui-spinner-layer-${index}` : ''
    }">` +
    '<div class="mdui-spinner-circle-clipper mdui-spinner-left">' +
    '<div class="mdui-spinner-circle"></div>' +
    '</div>' +
    '<div class="mdui-spinner-gap-patch">' +
    '<div class="mdui-spinner-circle"></div>' +
    '</div>' +
    '<div class="mdui-spinner-circle-clipper mdui-spinner-right">' +
    '<div class="mdui-spinner-circle"></div>' +
    '</div>' +
    '</div>'
  );
}

/**
 * ��� HTML
 * @param spinner
 */
function fillHTML(spinner: HTMLElement): void {
  const $spinner = $(spinner);

  const layer = $spinner.hasClass('mdui-spinner-colorful')
    ? layerHTML(1) + layerHTML(2) + layerHTML(3) + layerHTML(4)
    : layerHTML();

  $spinner.html(layer);
}

$(() => {
  // ҳ���������Զ���� HTML �ṹ
  mdui.mutation('.mdui-spinner', function () {
    fillHTML(this);
  });
});

mdui.updateSpinners = function (
  selector?: Selector | HTMLElement | ArrayLike<HTMLElement>,
): void {
  const $elements = isUndefined(selector) ? $('.mdui-spinner') : $(selector);

  $elements.each(function () {
    fillHTML(this);
  });
};
