import $ from 'mdui.jq/es/$';
import extend from 'mdui.jq/es/functions/extend';
import 'mdui.jq/es/methods/addClass';
import 'mdui.jq/es/methods/appendTo';
import 'mdui.jq/es/methods/attr';
import 'mdui.jq/es/methods/each';
import 'mdui.jq/es/methods/find';
import 'mdui.jq/es/methods/is';
import 'mdui.jq/es/methods/on';
import 'mdui.jq/es/methods/outerHeight';
import 'mdui.jq/es/methods/parent';
import 'mdui.jq/es/methods/parents';
import 'mdui.jq/es/methods/remove';
import 'mdui.jq/es/methods/removeClass';
import 'mdui.jq/es/methods/text';
import 'mdui.jq/es/methods/trigger';
import 'mdui.jq/es/methods/val';
import Selector from 'mdui.jq/es/types/Selector';
import { isUndefined } from 'mdui.jq/es/utils';
import mdui from '../../mdui';
import '../../global/mutation';
import { $document } from '../../utils/dom';

declare module '../../interfaces/MduiStatic' {
  interface MduiStatic {
    /**
     * ��̬�޸����ı������Ҫ���ø÷������³�ʼ���ı���
     *
     * �������˲�������ֻ��ʼ���ò�����Ӧ���ı�����û�д�������������³�ʼ�������ı���
     * @param selector CSS ѡ�������� DOM Ԫ�ء��� DOM Ԫ����ɵ����顢�� JQ ����
     */
    updateTextFields(
      selector?: Selector | HTMLElement | ArrayLike<HTMLElement>,
    ): void;
  }
}

type INPUT_EVENT_DATA = {
  reInit?: boolean;
  domLoadedEvent?: boolean;
};

const defaultData: INPUT_EVENT_DATA = {
  reInit: false,
  domLoadedEvent: false,
};

/**
 * ������¼�
 * @param event
 * @param data
 */
function inputEvent(event: Event, data: INPUT_EVENT_DATA = {}): void {
  data = extend({}, defaultData, data);

  const input = event.target as HTMLInputElement;
  const $input = $(input);
  const eventType = event.type;
  const value = $input.val() as string;

  // �ı�������
  const inputType = $input.attr('type') || '';
  if (
    ['checkbox', 'button', 'submit', 'range', 'radio', 'image'].indexOf(
      inputType,
    ) > -1
  ) {
    return;
  }

  const $textfield = $input.parent('.mdui-textfield');

  // ������Ƿ�۽�
  if (eventType === 'focus') {
    $textfield.addClass('mdui-textfield-focus');
  }

  if (eventType === 'blur') {
    $textfield.removeClass('mdui-textfield-focus');
  }

  // ������Ƿ�Ϊ��
  if (eventType === 'blur' || eventType === 'input') {
    value
      ? $textfield.addClass('mdui-textfield-not-empty')
      : $textfield.removeClass('mdui-textfield-not-empty');
  }

  // ������Ƿ����
  input.disabled
    ? $textfield.addClass('mdui-textfield-disabled')
    : $textfield.removeClass('mdui-textfield-disabled');

  // ������֤
  if (
    (eventType === 'input' || eventType === 'blur') &&
    !data.domLoadedEvent &&
    input.validity
  ) {
    input.validity.valid
      ? $textfield.removeClass('mdui-textfield-invalid-html5')
      : $textfield.addClass('mdui-textfield-invalid-html5');
  }

  // textarea �߶��Զ�����
  if ($input.is('textarea')) {
    // IE bug��textarea ��ֵ��Ϊ������У�������������ʱ��textarea �ĸ߶Ȳ�׼ȷ
    //         ��ʱ���ڼ���߶�ǰ����ֵ�Ŀ�ͷ����һ���ո񣬼�������Ƴ��ո�
    const inputValue = value;
    let hasExtraSpace = false;

    if (inputValue.replace(/[\r\n]/g, '') === '') {
      $input.val(' ' + inputValue);
      hasExtraSpace = true;
    }

    // ���� textarea �߶�
    $input.outerHeight('');
    const height = $input.outerHeight();
    const scrollHeight = input.scrollHeight;

    if (scrollHeight > height) {
      $input.outerHeight(scrollHeight);
    }

    // �����꣬��ԭ textarea ��ֵ
    if (hasExtraSpace) {
      $input.val(inputValue);
    }
  }

  // ʵʱ����ͳ��
  if (data.reInit) {
    $textfield.find('.mdui-textfield-counter').remove();
  }

  const maxLength = $input.attr('maxlength');
  if (maxLength) {
    if (data.reInit || data.domLoadedEvent) {
      $(
        '<div class="mdui-textfield-counter">' +
          `<span class="mdui-textfield-counter-inputed"></span> / ${maxLength}` +
          '</div>',
      ).appendTo($textfield);
    }

    $textfield
      .find('.mdui-textfield-counter-inputed')
      .text(value.length.toString());
  }

  // �� �����ı���������ʾ������ͳ�� ʱ�������ı���ײ��ڱ߾�
  if (
    $textfield.find('.mdui-textfield-helper').length ||
    $textfield.find('.mdui-textfield-error').length ||
    maxLength
  ) {
    $textfield.addClass('mdui-textfield-has-bottom');
  }
}

$(() => {
  // ���¼�
  $document.on(
    'input focus blur',
    '.mdui-textfield-input',
    { useCapture: true },
    inputEvent,
  );

  // ��չ���ı���չ��
  $document.on(
    'click',
    '.mdui-textfield-expandable .mdui-textfield-icon',
    function () {
      $(this as HTMLElement)
        .parents('.mdui-textfield')
        .addClass('mdui-textfield-expanded')
        .find('.mdui-textfield-input')[0]
        .focus();
    },
  );

  // ��չ���ı���ر�
  $document.on(
    'click',
    '.mdui-textfield-expanded .mdui-textfield-close',
    function () {
      $(this)
        .parents('.mdui-textfield')
        .removeClass('mdui-textfield-expanded')
        .find('.mdui-textfield-input')
        .val('');
    },
  );

  /**
   * ��ʼ���ı���
   */
  mdui.mutation('.mdui-textfield', function () {
    $(this).find('.mdui-textfield-input').trigger('input', {
      domLoadedEvent: true,
    });
  });
});

mdui.updateTextFields = function (
  selector?: Selector | HTMLElement | ArrayLike<HTMLElement>,
): void {
  const $elements = isUndefined(selector) ? $('.mdui-textfield') : $(selector);

  $elements.each((_, element) => {
    $(element).find('.mdui-textfield-input').trigger('input', {
      reInit: true,
    });
  });
};
