import Selector from 'mdui.jq/es/types/Selector';
import mdui from '../../mdui';
import 'mdui.jq/es/methods/on';
import { $document } from '../../utils/dom';
import { currentInst, OPTIONS, Dialog } from './class';

declare module '../../interfaces/MduiStatic' {
  interface MduiStatic {
    /**
     * Dialog ���
     *
     * ��ͨ�� `new mdui.Dialog()` ����
     */
    Dialog: {
      /**
       * ʵ���� Dialog ���
       * @param selector CSS ѡ�������� DOM Ԫ�ء��� JQ ����
       * @param options ���ò���
       */
      new (
        selector: Selector | HTMLElement | ArrayLike<HTMLElement>,
        options?: OPTIONS,
      ): Dialog;
    };
  }
}

// esc ����ʱ�رնԻ���
$document.on('keydown', (event: Event) => {
  if (
    currentInst &&
    currentInst.options.closeOnEsc &&
    currentInst.state === 'opened' &&
    (event as KeyboardEvent).keyCode === 27
  ) {
    currentInst.close();
  }
});

mdui.Dialog = Dialog;
