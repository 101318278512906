import $ from 'mdui.jq/es/$';
import contains from 'mdui.jq/es/functions/contains';
import extend from 'mdui.jq/es/functions/extend';
import { JQ } from 'mdui.jq/es/JQ';
import 'mdui.jq/es/methods/addClass';
import 'mdui.jq/es/methods/attr';
import 'mdui.jq/es/methods/children';
import 'mdui.jq/es/methods/css';
import 'mdui.jq/es/methods/data';
import 'mdui.jq/es/methods/each';
import 'mdui.jq/es/methods/find';
import 'mdui.jq/es/methods/first';
import 'mdui.jq/es/methods/hasClass';
import 'mdui.jq/es/methods/height';
import 'mdui.jq/es/methods/is';
import 'mdui.jq/es/methods/on';
import 'mdui.jq/es/methods/parent';
import 'mdui.jq/es/methods/parents';
import 'mdui.jq/es/methods/removeClass';
import 'mdui.jq/es/methods/width';
import Selector from 'mdui.jq/es/types/Selector';
import mdui from '../../mdui';
import '../../jq_extends/methods/transformOrigin';
import '../../jq_extends/methods/transitionEnd';
import '../../jq_extends/static/throttle';
import { componentEvent } from '../../utils/componentEvent';
import { $document, $window } from '../../utils/dom';

declare module '../../interfaces/MduiStatic' {
  interface MduiStatic {
    /**
     * Menu ���
     *
     * ��ͨ�� `new mdui.Menu()` ����
     */
    Menu: {
      /**
       * ʵ���� Menu ���
       * @param anchorSelector �����˵���Ԫ�ص� CSS ѡ�������� DOM Ԫ�ء��� JQ ����
       * @param menuSelector �˵��� CSS ѡ�������� DOM Ԫ�ء��� JQ ����
       * @param options ���ò���
       */
      new (
        anchorSelector: Selector | HTMLElement | ArrayLike<HTMLElement>,
        menuSelector: Selector | HTMLElement | ArrayLike<HTMLElement>,
        options?: OPTIONS,
      ): Menu;
    };
  }
}

type OPTIONS = {
  /**
   * �˵�����ڴ�������Ԫ�ص�λ�ã�Ĭ��Ϊ `auto`��
   * ȡֵ��Χ������
   *   `top`: �˵��ڴ�������Ԫ�ص��Ϸ�
   *   `bottom`: �˵��ڴ�������Ԫ�ص��·�
   *   `center`: �˵��ڴ����д�ֱ����
   *   `auto`: �Զ��ж�λ�á����ȼ�Ϊ��`bottom` > `top` > `center`
   */
  position?: 'auto' | 'top' | 'bottom' | 'center';

  /**
   * �˵��봥������Ԫ�صĶ��䷽ʽ��Ĭ��Ϊ `auto`��
   * ȡֵ��Χ������
   *   `left`: �˵��봥������Ԫ�������
   *   `right`: �˵��봥������Ԫ���Ҷ���
   *   `center`: �˵��ڴ�����ˮƽ����
   *   `auto`: �Զ��ж�λ�ã����ȼ�Ϊ��`left` > `right` > `center`
   */
  align?: 'auto' | 'left' | 'right' | 'center';

  /**
   * �˵��봰�ڱ߿����ٱ��ֶ��ټ�࣬��λΪ px��Ĭ��Ϊ `16`
   */
  gutter?: number;

  /**
   * �˵��Ķ�λ��ʽ��Ĭ��Ϊ `false`��
   * Ϊ `true` ʱ���˵�ʹ�� fixed ��λ����ҳ�����ʱ���˵��������ڴ��ڹ̶�λ�ã����������������
   * Ϊ `false` ʱ���˵�ʹ�� absolute ��λ����ҳ�����ʱ���˵�������ҳ��һ�������
   */
  fixed?: boolean;

  /**
   * �˵��Ƿ񸲸��ڴ�������Ԫ�ص����棬Ĭ��Ϊ `auto`
   * Ϊ `true` ʱ��ʹ�˵������ڴ�������Ԫ�ص�����
   * Ϊ `false` ʱ��ʹ�˵������Ǵ�������Ԫ��
   * Ϊ `auto` ʱ���򵥲˵����Ǵ�������Ԫ�ء������˵������Ǵ�������Ԫ��
   */
  covered?: boolean | 'auto';

  /**
   * �Ӳ˵��Ĵ�����ʽ��Ĭ��Ϊ `hover`
   * Ϊ `click` ʱ�����ʱ�����Ӳ˵�
   * Ϊ `hover` ʱ���������ʱ�����Ӳ˵�
   */
  subMenuTrigger?: 'click' | 'hover';

  /**
   * �Ӳ˵��Ĵ����ӳ�ʱ�䣨��λ�����룩��ֻ���� `subMenuTrigger: hover` ʱ�������������Ч��Ĭ��Ϊ `200`
   */
  subMenuDelay?: number;
};

type EVENT = 'open' | 'opened' | 'close' | 'closed';
type STATE = 'opening' | 'opened' | 'closing' | 'closed';

const DEFAULT_OPTIONS: OPTIONS = {
  position: 'auto',
  align: 'auto',
  gutter: 16,
  fixed: false,
  covered: 'auto',
  subMenuTrigger: 'hover',
  subMenuDelay: 200,
};

class Menu {
  /**
   * �����˵���Ԫ�ص� JQ ����
   */
  public $anchor: JQ;

  /**
   * �˵�Ԫ�ص� JQ ����
   */
  public $element: JQ;

  /**
   * ���ò���
   */
  public options: OPTIONS = extend({}, DEFAULT_OPTIONS);

  /**
   * ��ǰ�˵�״̬
   */
  private state: STATE = 'closed';

  /**
   * �Ƿ��Ǽ����˵�
   */
  private isCascade: boolean;

  /**
   * �˵��Ƿ񸲸��ڴ�������Ԫ�ص�����
   */
  private isCovered: boolean;

  public constructor(
    anchorSelector: Selector | HTMLElement | ArrayLike<HTMLElement>,
    menuSelector: Selector | HTMLElement | ArrayLike<HTMLElement>,
    options: OPTIONS = {},
  ) {
    this.$anchor = $(anchorSelector).first();
    this.$element = $(menuSelector).first();

    // �����˵���Ԫ�� �� �˵�������ͬ����Ԫ�أ�����˵����ܲ��ܶ�λ
    if (!this.$anchor.parent().is(this.$element.parent())) {
      throw new Error('anchorSelector and menuSelector must be siblings');
    }

    extend(this.options, options);

    // �Ƿ��Ǽ����˵�
    this.isCascade = this.$element.hasClass('mdui-menu-cascade');

    // covered ��������
    this.isCovered =
      this.options.covered === 'auto' ? !this.isCascade : this.options.covered!;

    // ��������˵��л�
    this.$anchor.on('click', () => this.toggle());

    // ����˵���������رղ˵�
    $document.on('click touchstart', (event: Event) => {
      const $target = $(event.target as HTMLElement);

      if (
        this.isOpen() &&
        !$target.is(this.$element) &&
        !contains(this.$element[0], $target[0]) &&
        !$target.is(this.$anchor) &&
        !contains(this.$anchor[0], $target[0])
      ) {
        this.close();
      }
    });

    // ��������Ӳ˵��Ĳ˵���Ŀ�رղ˵�
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const that = this;
    $document.on('click', '.mdui-menu-item', function () {
      const $item = $(this);

      if (
        !$item.find('.mdui-menu').length &&
        $item.attr('disabled') === undefined
      ) {
        that.close();
      }
    });

    // �󶨵����������뺬�Ӳ˵�����Ŀ���¼�
    this.bindSubMenuEvent();

    // ���ڴ�С�仯ʱ�����µ����˵�λ��
    $window.on(
      'resize',
      $.throttle(() => this.readjust(), 100),
    );
  }

  /**
   * �Ƿ�Ϊ��״̬
   */
  private isOpen(): boolean {
    return this.state === 'opening' || this.state === 'opened';
  }

  /**
   * ��������¼�
   * @param name
   */
  private triggerEvent(name: EVENT): void {
    componentEvent(name, 'menu', this.$element, this);
  }

  /**
   * �������˵�λ��
   */
  private readjust(): void {
    let menuLeft;
    let menuTop;

    // �˵�λ�úͷ���
    let position: 'bottom' | 'top' | 'center';
    let align: 'left' | 'right' | 'center';

    // window ���ڵĿ��Ⱥ͸߶�
    const windowHeight = $window.height();
    const windowWidth = $window.width();

    // ���ò���
    const gutter = this.options.gutter!;
    const isCovered = this.isCovered;
    const isFixed = this.options.fixed;

    // �����������
    let transformOriginX;
    let transformOriginY;

    // �˵���ԭʼ���Ⱥ͸߶�
    const menuWidth = this.$element.width();
    const menuHeight = this.$element.height();

    // �����˵���Ԫ���ڴ����е�λ��
    const anchorRect = this.$anchor[0].getBoundingClientRect();
    const anchorTop = anchorRect.top;
    const anchorLeft = anchorRect.left;
    const anchorHeight = anchorRect.height;
    const anchorWidth = anchorRect.width;
    const anchorBottom = windowHeight - anchorTop - anchorHeight;
    const anchorRight = windowWidth - anchorLeft - anchorWidth;

    // ����Ԫ�������ӵ�ж�λ���Եĸ�Ԫ�ص�λ��
    const anchorOffsetTop = this.$anchor[0].offsetTop;
    const anchorOffsetLeft = this.$anchor[0].offsetLeft;

    // �Զ��жϲ˵�λ��
    if (this.options.position === 'auto') {
      if (anchorBottom + (isCovered ? anchorHeight : 0) > menuHeight + gutter) {
        // �ж��·��Ƿ�ŵ��²˵�
        position = 'bottom';
      } else if (
        anchorTop + (isCovered ? anchorHeight : 0) >
        menuHeight + gutter
      ) {
        // �ж��Ϸ��Ƿ�ŵ��²˵�
        position = 'top';
      } else {
        // ���¶��Ų��£�������ʾ
        position = 'center';
      }
    } else {
      position = this.options.position!;
    }

    // �Զ��жϲ˵����뷽ʽ
    if (this.options.align === 'auto') {
      if (anchorRight + anchorWidth > menuWidth + gutter) {
        // �ж��Ҳ��Ƿ�ŵ��²˵�
        align = 'left';
      } else if (anchorLeft + anchorWidth > menuWidth + gutter) {
        // �ж�����Ƿ�ŵ��²˵�
        align = 'right';
      } else {
        // ���Ҷ��Ų��£�������ʾ
        align = 'center';
      }
    } else {
      align = this.options.align!;
    }

    // ���ò˵�λ��
    if (position === 'bottom') {
      transformOriginY = '0';
      menuTop =
        (isCovered ? 0 : anchorHeight) +
        (isFixed ? anchorTop : anchorOffsetTop);
    } else if (position === 'top') {
      transformOriginY = '100%';
      menuTop =
        (isCovered ? anchorHeight : 0) +
        (isFixed ? anchorTop - menuHeight : anchorOffsetTop - menuHeight);
    } else {
      transformOriginY = '50%';

      // =====================�ڴ����о���
      // ��ʾ�Ĳ˵��ĸ߶ȣ��򵥲˵��߶Ȳ��������ڸ߶ȣ������������ڲ˵��ڲ���ʾ������
      // �����˵��ڲ����������ֹ�����
      let menuHeightTemp = menuHeight;

      // �򵥲˵��ȴ��ڸ�ʱ�����Ʋ˵��߶�
      if (!this.isCascade) {
        if (menuHeight + gutter * 2 > windowHeight) {
          menuHeightTemp = windowHeight - gutter * 2;
          this.$element.height(menuHeightTemp);
        }
      }

      menuTop =
        (windowHeight - menuHeightTemp) / 2 +
        (isFixed ? 0 : anchorOffsetTop - anchorTop);
    }

    this.$element.css('top', `${menuTop}px`);

    // ���ò˵����뷽ʽ
    if (align === 'left') {
      transformOriginX = '0';
      menuLeft = isFixed ? anchorLeft : anchorOffsetLeft;
    } else if (align === 'right') {
      transformOriginX = '100%';
      menuLeft = isFixed
        ? anchorLeft + anchorWidth - menuWidth
        : anchorOffsetLeft + anchorWidth - menuWidth;
    } else {
      transformOriginX = '50%';

      //=======================�ڴ����о���
      // ��ʾ�Ĳ˵��Ŀ��ȣ��˵����Ȳ��ܳ������ڿ���
      let menuWidthTemp = menuWidth;

      // �˵��ȴ��ڿ������Ʋ˵�����
      if (menuWidth + gutter * 2 > windowWidth) {
        menuWidthTemp = windowWidth - gutter * 2;
        this.$element.width(menuWidthTemp);
      }

      menuLeft =
        (windowWidth - menuWidthTemp) / 2 +
        (isFixed ? 0 : anchorOffsetLeft - anchorLeft);
    }

    this.$element.css('left', `${menuLeft}px`);

    // ���ò˵���������
    this.$element.transformOrigin(`${transformOriginX} ${transformOriginY}`);
  }

  /**
   * �����Ӳ˵���λ��
   * @param $submenu
   */
  private readjustSubmenu($submenu: JQ): void {
    const $item = $submenu.parent('.mdui-menu-item');

    let submenuTop;
    let submenuLeft;

    // �Ӳ˵�λ�úͷ���
    let position: 'top' | 'bottom';
    let align: 'left' | 'right';

    // window ���ڵĿ��Ⱥ͸߶�
    const windowHeight = $window.height();
    const windowWidth = $window.width();

    // �����������
    let transformOriginX;
    let transformOriginY;

    // �Ӳ˵���ԭʼ���Ⱥ͸߶�
    const submenuWidth = $submenu.width();
    const submenuHeight = $submenu.height();

    // �����Ӳ˵��Ĳ˵���Ŀ��ȸ߶�
    const itemRect = $item[0].getBoundingClientRect();
    const itemWidth = itemRect.width;
    const itemHeight = itemRect.height;
    const itemLeft = itemRect.left;
    const itemTop = itemRect.top;

    // �жϲ˵�����λ��
    if (windowHeight - itemTop > submenuHeight) {
      // �ж��·��Ƿ�ŵ��²˵�
      position = 'bottom';
    } else if (itemTop + itemHeight > submenuHeight) {
      // �ж��Ϸ��Ƿ�ŵ��²˵�
      position = 'top';
    } else {
      // Ĭ�Ϸ����·�
      position = 'bottom';
    }

    // �жϲ˵�����λ��
    if (windowWidth - itemLeft - itemWidth > submenuWidth) {
      // �ж��Ҳ��Ƿ�ŵ��²˵�
      align = 'left';
    } else if (itemLeft > submenuWidth) {
      // �ж�����Ƿ�ŵ��²˵�
      align = 'right';
    } else {
      // Ĭ�Ϸ����Ҳ�
      align = 'left';
    }

    // ���ò˵�λ��
    if (position === 'bottom') {
      transformOriginY = '0';
      submenuTop = '0';
    } else if (position === 'top') {
      transformOriginY = '100%';
      submenuTop = -submenuHeight + itemHeight;
    }

    $submenu.css('top', `${submenuTop}px`);

    // ���ò˵����뷽ʽ
    if (align === 'left') {
      transformOriginX = '0';
      submenuLeft = itemWidth;
    } else if (align === 'right') {
      transformOriginX = '100%';
      submenuLeft = -submenuWidth;
    }

    $submenu.css('left', `${submenuLeft}px`);

    // ���ò˵���������
    $submenu.transformOrigin(`${transformOriginX} ${transformOriginY}`);
  }

  /**
   * ���Ӳ˵�
   * @param $submenu
   */
  private openSubMenu($submenu: JQ): void {
    this.readjustSubmenu($submenu);

    $submenu
      .addClass('mdui-menu-open')
      .parent('.mdui-menu-item')
      .addClass('mdui-menu-item-active');
  }

  /**
   * �ر��Ӳ˵�������Ƕ�׵��Ӳ˵�
   * @param $submenu
   */
  private closeSubMenu($submenu: JQ): void {
    // �ر��Ӳ˵�
    $submenu
      .removeClass('mdui-menu-open')
      .addClass('mdui-menu-closing')
      .transitionEnd(() => $submenu.removeClass('mdui-menu-closing'))

      // �Ƴ�����״̬����ʽ
      .parent('.mdui-menu-item')
      .removeClass('mdui-menu-item-active');

    // ѭ���ر�Ƕ�׵��Ӳ˵�
    $submenu.find('.mdui-menu').each((_, menu) => {
      const $subSubmenu = $(menu);

      $subSubmenu
        .removeClass('mdui-menu-open')
        .addClass('mdui-menu-closing')
        .transitionEnd(() => $subSubmenu.removeClass('mdui-menu-closing'))
        .parent('.mdui-menu-item')
        .removeClass('mdui-menu-item-active');
    });
  }

  /**
   * �л��Ӳ˵�״̬
   * @param $submenu
   */
  private toggleSubMenu($submenu: JQ): void {
    $submenu.hasClass('mdui-menu-open')
      ? this.closeSubMenu($submenu)
      : this.openSubMenu($submenu);
  }

  /**
   * ���Ӳ˵��¼�
   */
  private bindSubMenuEvent(): void {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const that = this;

    // ������Ӳ˵�
    this.$element.on('click', '.mdui-menu-item', function (event) {
      const $item = $(this as HTMLElement);
      const $target = $(event.target as HTMLElement);

      // ����״̬�˵�������
      if ($item.attr('disabled') !== undefined) {
        return;
      }

      // û�е�����Ӳ˵��Ĳ˵�����ʱ�����������������Ӳ˵��Ŀհ����򡢻�ָ����ϣ�
      if ($target.is('.mdui-menu') || $target.is('.mdui-divider')) {
        return;
      }

      // ��ֹð�ݣ�����˵���ʱֻ�����һ���� mdui-menu-item ����Ч��������ð��
      if (!$target.parents('.mdui-menu-item').first().is($item)) {
        return;
      }

      // ��ǰ�˵����Ӳ˵�
      const $submenu = $item.children('.mdui-menu');

      // �ȹرճ���ǰ�Ӳ˵��������ͬ���Ӳ˵�
      $item
        .parent('.mdui-menu')
        .children('.mdui-menu-item')
        .each((_, item) => {
          const $tmpSubmenu = $(item).children('.mdui-menu');

          if (
            $tmpSubmenu.length &&
            (!$submenu.length || !$tmpSubmenu.is($submenu))
          ) {
            that.closeSubMenu($tmpSubmenu);
          }
        });

      // �л���ǰ�Ӳ˵�
      if ($submenu.length) {
        that.toggleSubMenu($submenu);
      }
    });

    if (this.options.subMenuTrigger === 'hover') {
      // ��ʱ�洢 setTimeout ����
      let timeout: any = null;
      let timeoutOpen: any = null;

      this.$element.on(
        'mouseover mouseout',
        '.mdui-menu-item',
        function (event) {
          const $item = $(this as HTMLElement);
          const eventType = event.type;
          const $relatedTarget = $(
            (event as MouseEvent).relatedTarget as HTMLElement,
          );

          // ����״̬�Ĳ˵�������
          if ($item.attr('disabled') !== undefined) {
            return;
          }

          // �� mouseover ģ�� mouseenter
          if (eventType === 'mouseover') {
            if (
              !$item.is($relatedTarget) &&
              contains($item[0], $relatedTarget[0])
            ) {
              return;
            }
          }

          // �� mouseout ģ�� mouseleave
          else if (eventType === 'mouseout') {
            if (
              $item.is($relatedTarget) ||
              contains($item[0], $relatedTarget[0])
            ) {
              return;
            }
          }

          // ��ǰ�˵����µ��Ӳ˵���δ�ش���
          const $submenu = $item.children('.mdui-menu');

          // �������˵���ʱ����ʾ�˵����µ��Ӳ˵�
          if (eventType === 'mouseover') {
            if ($submenu.length) {
              // ��ǰ�Ӳ˵�׼����ʱ�������ǰ�Ӳ˵���׼���Źرգ������ٹر���
              const tmpClose = $submenu.data('timeoutClose.mdui.menu');
              if (tmpClose) {
                clearTimeout(tmpClose);
              }

              // �����ǰ�Ӳ˵��Ѿ��򿪣�������
              if ($submenu.hasClass('mdui-menu-open')) {
                return;
              }

              // ��ǰ�Ӳ˵�׼����ʱ������׼���򿪵��Ӳ˵������ٴ���
              clearTimeout(timeoutOpen);

              // ׼���򿪵�ǰ�Ӳ˵�
              timeout = timeoutOpen = setTimeout(
                () => that.openSubMenu($submenu),
                that.options.subMenuDelay,
              );

              $submenu.data('timeoutOpen.mdui.menu', timeout);
            }
          }

          // ����Ƴ��˵���ʱ���رղ˵����µ��Ӳ˵�
          else if (eventType === 'mouseout') {
            if ($submenu.length) {
              // ����Ƴ��˵���ʱ�������ǰ�˵����µ��Ӳ˵���׼���򿪣������ٴ���
              const tmpOpen = $submenu.data('timeoutOpen.mdui.menu');
              if (tmpOpen) {
                clearTimeout(tmpOpen);
              }

              // ׼���رյ�ǰ�Ӳ˵�
              timeout = setTimeout(
                () => that.closeSubMenu($submenu),
                that.options.subMenuDelay,
              );

              $submenu.data('timeoutClose.mdui.menu', timeout);
            }
          }
        },
      );
    }
  }

  /**
   * ���������ص�
   */
  private transitionEnd(): void {
    this.$element.removeClass('mdui-menu-closing');

    if (this.state === 'opening') {
      this.state = 'opened';
      this.triggerEvent('opened');
    }

    if (this.state === 'closing') {
      this.state = 'closed';
      this.triggerEvent('closed');

      // �رպ󣬻ָ��˵���ʽ��Ĭ��״̬�����ָ� fixed ��λ
      this.$element.css({
        top: '',
        left: '',
        width: '',
        position: 'fixed',
      });
    }
  }

  /**
   * �л��˵�״̬
   */
  public toggle(): void {
    this.isOpen() ? this.close() : this.open();
  }

  /**
   * �򿪲˵�
   */
  public open(): void {
    if (this.isOpen()) {
      return;
    }

    this.state = 'opening';
    this.triggerEvent('open');

    this.readjust();

    this.$element
      // �˵�����״̬ʹ��ʹ�� fixed ��λ��
      .css('position', this.options.fixed ? 'fixed' : 'absolute')
      .addClass('mdui-menu-open')
      .transitionEnd(() => this.transitionEnd());
  }

  /**
   * �رղ˵�
   */
  public close(): void {
    if (!this.isOpen()) {
      return;
    }

    this.state = 'closing';
    this.triggerEvent('close');

    // �˵���ʼ�ر�ʱ���ر������Ӳ˵�
    this.$element.find('.mdui-menu').each((_, submenu) => {
      this.closeSubMenu($(submenu));
    });

    this.$element
      .removeClass('mdui-menu-open')
      .addClass('mdui-menu-closing')
      .transitionEnd(() => this.transitionEnd());
  }
}

mdui.Menu = Menu;
