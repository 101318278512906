import $ from 'mdui.jq/es/$';
import extend from 'mdui.jq/es/functions/extend';
import { JQ } from 'mdui.jq/es/JQ';
import 'mdui.jq/es/methods/addClass';
import 'mdui.jq/es/methods/css';
import 'mdui.jq/es/methods/each';
import 'mdui.jq/es/methods/find';
import 'mdui.jq/es/methods/first';
import 'mdui.jq/es/methods/hasClass';
import 'mdui.jq/es/methods/last';
import 'mdui.jq/es/methods/on';
import 'mdui.jq/es/methods/parents';
import 'mdui.jq/es/methods/removeClass';
import Selector from 'mdui.jq/es/types/Selector';
import mdui from '../../mdui';
import '../../jq_extends/methods/transitionEnd';
import { componentEvent } from '../../utils/componentEvent';
import { $document } from '../../utils/dom';
import { startEvent } from '../../utils/touchHandler';

declare module '../../interfaces/MduiStatic' {
  interface MduiStatic {
    /**
     * ����������ť���
     *
     * ��ͨ�� `new mdui.Fab()` ����
     */
    Fab: {
      /**
       * ʵ���� Fab ���
       * @param selector CSS ѡ�������� DOM Ԫ�ء��� JQ ����
       * @param options ���ò���
       */
      new (
        selector: Selector | HTMLElement | ArrayLike<HTMLElement>,
        options?: OPTIONS,
      ): Fab;
    };
  }
}

type OPTIONS = {
  /**
   * ������ʽ��`hover`: �������������`click`: �������
   *
   * Ĭ��Ϊ `hover`
   */
  trigger?: 'click' | 'hover';
};

type STATE = 'opening' | 'opened' | 'closing' | 'closed';
type EVENT = 'open' | 'opened' | 'close' | 'closed';

const DEFAULT_OPTIONS: OPTIONS = {
  trigger: 'hover',
};

class Fab {
  /**
   * Fab Ԫ�ص� JQ ����
   */
  public $element: JQ;

  /**
   * ���ò���
   */
  public options: OPTIONS = extend({}, DEFAULT_OPTIONS);

  /**
   * ��ǰ fab ��״̬
   */
  private state: STATE = 'closed';

  /**
   * ��ťԪ��
   */
  private $btn: JQ;

  /**
   * ���Ų˵�Ԫ��
   */
  private $dial: JQ;

  /**
   * ���Ų˵��ڵİ�ť
   */
  private $dialBtns: JQ;

  public constructor(
    selector: Selector | HTMLElement | ArrayLike<HTMLElement>,
    options: OPTIONS = {},
  ) {
    this.$element = $(selector).first();

    extend(this.options, options);

    this.$btn = this.$element.find('.mdui-fab');
    this.$dial = this.$element.find('.mdui-fab-dial');
    this.$dialBtns = this.$dial.find('.mdui-fab');

    if (this.options.trigger === 'hover') {
      this.$btn.on('touchstart mouseenter', () => this.open());
      this.$element.on('mouseleave', () => this.close());
    }

    if (this.options.trigger === 'click') {
      this.$btn.on(startEvent, () => this.open());
    }

    // ������Ļ�����ط��رտ��ٲ���
    $document.on(startEvent, (event) => {
      if ($(event.target as HTMLElement).parents('.mdui-fab-wrapper').length) {
        return;
      }

      this.close();
    });
  }

  /**
   * ��������¼�
   * @param name
   */
  private triggerEvent(name: EVENT): void {
    componentEvent(name, 'fab', this.$element, this);
  }

  /**
   * ��ǰ�Ƿ�Ϊ��״̬
   */
  private isOpen(): boolean {
    return this.state === 'opening' || this.state === 'opened';
  }

  /**
   * �򿪿��ٲ��Ų˵�
   */
  public open(): void {
    if (this.isOpen()) {
      return;
    }

    // Ϊ�˵��еİ�ť���Ӳ�ͬ�� transition-delay
    this.$dialBtns.each((index, btn) => {
      const delay = `${15 * (this.$dialBtns.length - index)}ms`;

      btn.style.transitionDelay = delay;
      btn.style.webkitTransitionDelay = delay;
    });

    this.$dial.css('height', 'auto').addClass('mdui-fab-dial-show');

    // �����ť�д��� .mdui-fab-opened ��ͼ�꣬�����ͼ���л�
    if (this.$btn.find('.mdui-fab-opened').length) {
      this.$btn.addClass('mdui-fab-opened');
    }

    this.state = 'opening';
    this.triggerEvent('open');

    // ��˳��Ϊ���µ�������򿪣�������Ĵ򿪺�ű�ʾ�������
    this.$dialBtns.first().transitionEnd(() => {
      if (this.$btn.hasClass('mdui-fab-opened')) {
        this.state = 'opened';
        this.triggerEvent('opened');
      }
    });
  }

  /**
   * �رտ��ٲ��Ų˵�
   */
  public close(): void {
    if (!this.isOpen()) {
      return;
    }

    // Ϊ�˵��еİ�ť���Ӳ�ͬ�� transition-delay
    this.$dialBtns.each((index, btn) => {
      const delay = `${15 * index}ms`;

      btn.style.transitionDelay = delay;
      btn.style.webkitTransitionDelay = delay;
    });

    this.$dial.removeClass('mdui-fab-dial-show');
    this.$btn.removeClass('mdui-fab-opened');
    this.state = 'closing';
    this.triggerEvent('close');

    // �����������ιرգ����һ���رպ�ű�ʾ�������
    this.$dialBtns.last().transitionEnd(() => {
      if (this.$btn.hasClass('mdui-fab-opened')) {
        return;
      }

      this.state = 'closed';
      this.triggerEvent('closed');
      this.$dial.css('height', 0);
    });
  }

  /**
   * �л����ٲ��Ų˵��Ĵ�״̬
   */
  public toggle(): void {
    this.isOpen() ? this.close() : this.open();
  }

  /**
   * �Զ�������ʽ��ʾ��������������ť
   */
  public show(): void {
    this.$element.removeClass('mdui-fab-hide');
  }

  /**
   * �Զ�������ʽ������������������ť
   */
  public hide(): void {
    this.$element.addClass('mdui-fab-hide');
  }

  /**
   * ���ص�ǰ���ٲ��Ų˵��Ĵ�״̬������������״̬��`opening`��`opened`��`closing`��`closed`
   */
  public getState(): STATE {
    return this.state;
  }
}

mdui.Fab = Fab;
