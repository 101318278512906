import $ from 'mdui.jq/es/$';
import { JQ } from 'mdui.jq/es/JQ';
import { isNumber } from 'mdui.jq/es/utils';
import 'mdui.jq/es/methods/each';

declare module 'mdui.jq/es/JQ' {
  interface JQ<T = HTMLElement> {
    /**
     * ���õ�ǰԪ�ص� transition-duration ����
     * @param duration �����Ǵ���λ��ֵ����������λ�����Զ����� `ms` ��Ϊ��λ
     * @example
```js
$('.box').transition('300ms');
$('.box').transition(300);
```
     */
    transition(duration: string | number): this;
  }
}

$.fn.transition = function (this: JQ, duration: string | number): JQ {
  if (isNumber(duration)) {
    duration = `${duration}ms`;
  }

  return this.each(function () {
    this.style.webkitTransitionDuration = duration as string;
    this.style.transitionDuration = duration as string;
  });
};
