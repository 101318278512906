import $ from 'mdui.jq/es/$';
import 'mdui.jq/es/methods/data';
import 'mdui.jq/es/methods/removeClass';
import 'mdui.jq/es/methods/remove';
import '../methods/transitionEnd';

declare module 'mdui.jq/es/interfaces/JQStatic' {
  interface JQStatic {
    /**
     * �������ֲ�
     *
     * ��������˶�� $.showOverlay() ����ʾ���ֲ㣬��Ҳ��Ҫ������ͬ������ $.hideOverlay() �����������ֲ㡣����ͨ��������� true ��ǿ���������ֲ㡣
     * @param force �Ƿ�ǿ����������
     * @example
```js
$.hideOverlay();
```
     * @example
```js
$.hideOverlay(true);
```
     */
    hideOverlay(force?: boolean): void;
  }
}

$.hideOverlay = function (force = false): void {
  const $overlay = $('.mdui-overlay');

  if (!$overlay.length) {
    return;
  }

  let level = force ? 1 : $overlay.data('_overlay_level');

  if (level > 1) {
    $overlay.data('_overlay_level', --level);
    return;
  }

  $overlay
    .data('_overlay_level', 0)
    .removeClass('mdui-overlay-show')
    .data('_overlay_is_deleted', true)
    .transitionEnd(() => {
      if ($overlay.data('_overlay_is_deleted')) {
        $overlay.remove();
      }
    });
};
