/**
 * touch �¼���� 500ms �ڽ��� mousedown �¼�
 *
 * ��֧�ִ��ص���Ļ���¼�˳��Ϊ mousedown -> mouseup -> click
 * ֧�ִ��ص���Ļ���¼�˳��Ϊ touchstart -> touchend -> mousedown -> mouseup -> click
 *
 * ��ÿһ���¼��ж�ʹ�� TouchHandler.isAllow(event) �ж��¼��Ƿ��ִ��
 * �� touchstart �� touchmove��touchend��touchcancel
 *
 * (function () {
 *   $document
 *     .on(start, function (e) {
 *       if (!isAllow(e)) {
 *         return;
 *       }
 *       register(e);
 *       console.log(e.type);
 *     })
 *     .on(move, function (e) {
 *       if (!isAllow(e)) {
 *         return;
 *       }
 *       console.log(e.type);
 *     })
 *     .on(end, function (e) {
 *       if (!isAllow(e)) {
 *         return;
 *       }
 *       console.log(e.type);
 *     })
 *     .on(unlock, register);
 * })();
 */

const startEvent = 'touchstart mousedown';
const moveEvent = 'touchmove mousemove';
const endEvent = 'touchend mouseup';
const cancelEvent = 'touchcancel mouseleave';
const unlockEvent = 'touchend touchmove touchcancel';

let touches = 0;

/**
 * ���¼��Ƿ���������ִ���¼�ǰ���ø÷����ж��¼��Ƿ����ִ��
 * ���Ѵ��� touch �¼�������ֹ֮�������¼�
 * @param event
 */
function isAllow(event: Event): boolean {
  return !(
    touches &&
    [
      'mousedown',
      'mouseup',
      'mousemove',
      'click',
      'mouseover',
      'mouseout',
      'mouseenter',
      'mouseleave',
    ].indexOf(event.type) > -1
  );
}

/**
 * �� touchstart �� touchmove��touchend��touchcancel �¼��е��ø÷���ע���¼�
 * @param event
 */
function register(event: Event): void {
  if (event.type === 'touchstart') {
    // ������ touch �¼�
    touches += 1;
  } else if (
    ['touchmove', 'touchend', 'touchcancel'].indexOf(event.type) > -1
  ) {
    // touch �¼����� 500ms ����������¼�����ֹ
    setTimeout(function () {
      if (touches) {
        touches -= 1;
      }
    }, 500);
  }
}

export {
  startEvent,
  moveEvent,
  endEvent,
  cancelEvent,
  unlockEvent,
  isAllow,
  register,
};
